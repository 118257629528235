import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ProgressBar from 'react-bootstrap/ProgressBar'

import moment from 'moment'

import AuthService from "../services/AuthService"
import ConfigService from "../services/ConfigService"

import { AlertMsg, AlertError, AlertLoading, handleScrollToTop } from 'helpers/AlertHelper'

const Config = (props) => {

    const [showMsg, setShowMsg] = useState(false)
    const [showAlert, setShowAlert] = useState(false)

    const [user, setUser] = useState(false)
    const [config, setConfig] = useState(false)
    const [sendmailActive, setSendmailActive] = useState(false)
    const [smtpPassword, setSmtpPassword] = useState('')
    const [formValid, setFormValid] = useState(false)

    const [error, setError] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)

    const getConfig = async () => {
        await ConfigService.getConfig().then(
            response => {
                setConfig(response.data)
                setSendmailActive(response.data.sendmail.active)
                // Setting Local stored data to user var
                setUser(AuthService.getCurrentUser())
                setIsLoaded(true)
            },
            error => {
                setError(error)
            })
    }

    const handleCheckBoxChange = (e) => {
        const configParams = { ...config }
        switch (e.target.name) {
            case 'mapScrollWheelZoom':
                configParams.map.scrollWheelZoom = e.target.checked
                break
            case 'sendmailActive':
                configParams.sendmail.active = e.target.checked
                setSendmailActive(e.target.checked)
                break
            case 'sendmailSmtpSecure':
                configParams.sendmail.smtpSecure = e.target.checked
                break
        }
        setConfig(configParams)
    }

    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFieldValue(name, value)
    }

    const setFieldValue = (fieldName, value) => {
        const configParams = { ...config }
        switch (fieldName) {
            case 'appTitle':
                configParams.app.title = value
                break
            case 'appItemsListLimit':
                configParams.app.itemsListLimit = value
                break
            case 'gdprTextAdvise':
                configParams.gdpr.textAdvise = value
                break
            case 'gdprUrl':
                configParams.gdpr.url = value
                break
            case 'pdfUrlLogo':
                if (!configParams.pdf?.urlLogo) { configParams.pdf = { urlLogo: '' } }
                configParams.pdf.urlLogo = value
                break
            case 'mapCenterLat':
                configParams.map.center.lat = value
                break
            case 'mapCenterLng':
                configParams.map.center.lng = value
                break
            case 'mapZoom':
                configParams.map.zoom = value
                break
            case 'mapMapboxToken':
                configParams.map.mapboxToken = value
                break
            case 'sendmailFromName':
                configParams.sendmail.fromName = value
                break
            case 'sendmailFromEmail':
                configParams.sendmail.fromEmail = value
                break
            case 'sendmailSmtpHost':
                configParams.sendmail.smtpHost = value
                break
            case 'sendmailSmtpPort':
                configParams.sendmail.smtpPort = value
                break
            case 'sendmailSmtpUser':
                configParams.sendmail.smtpUser = value
                break
            case 'sendmailSmtpPassword':
                setSmtpPassword(value)
                break
        }
        setConfig(configParams)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        e.stopPropagation()

        const form = e.currentTarget
        if (form.checkValidity()) {

            const configParams = { ...config }
            delete configParams.createdAt
            delete configParams.changedAt
            delete configParams.collections
            delete configParams.disk
            delete configParams.__v
            delete configParams._id

            if (smtpPassword.length !== 0) {
                configParams.sendmail.smtpPassword = smtpPassword
            } else {
                delete configParams.sendmail.smtpPassword
            }

            await ConfigService.updateConfig(configParams).then(
                response => {
                    handleScrollToTop()
                    setConfig(response.data)
                    setFormValid(false)
                    setShowMsg({ text: 'Config params update success!', style: 'success' })
                    setShowAlert(true)
                },
                error => {
                    handleScrollToTop()
                    setFormValid(true)
                    setShowMsg({ text: error, style: 'danger' })
                    setShowAlert(true)
                })
        }
        setFormValid(true)
    }

    useEffect(() => {
        if (user) return
        if (config) return
        getConfig()
    }, [config, user])

    if (error) {
        return (
            <AlertError error={error} />
        )
    } else if (!isLoaded) {
        return (
            <AlertLoading isLoaded={isLoaded} />
        )
    }

    return (
        <Container className='p-1' fluid>
            <Row>
                <Col>
                    <h5>
                        <i className="bi bi-gear-fill"></i> <FormattedMessage id="navSysConfig" defaultMessage="System Config" />
                    </h5>
                </Col>
            </Row>
            <Row>
                <Col xs={9}>
                    <Form noValidate validated={formValid} onSubmit={handleSubmit}>
                        <AlertMsg message={showMsg.text} variant={showMsg.style} dismissible onCloseHandler={() => setShowAlert(false)} show={showAlert} />

                        <Form.Group className="mb-3" controlId="appTitle">
                            <Form.Label>APP Title:</Form.Label>
                            <Form.Control
                                type="text"
                                name="appTitle"
                                value={config.app.title}
                                placeholder='App title'
                                onChange={handleInputChange}
                                required="required"
                            />
                            <Form.Control.Feedback type="invalid">
                                Provide a title for your APP
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="appItemsListLimit">
                            <Form.Label>Items list limit:</Form.Label>
                            <Form.Control
                                type="text"
                                name="appItemsListLimit"
                                value={config.app.itemsListLimit}
                                placeholder='Items list by page'
                                onChange={handleInputChange}
                                required="required"
                            />
                            <Form.Control.Feedback type="invalid">
                                Provide a number of items to limit the list by page
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="gdprTextAdvise">
                            <Form.Label>Privacy Policy link text:</Form.Label>
                            <Form.Control
                                type="text"
                                name="gdprTextAdvise"
                                value={config.gdpr.textAdvise}
                                placeholder='GDPR Text Advise'
                                onChange={handleInputChange}
                                required="required"
                            />
                            <Form.Control.Feedback type="invalid">
                                Provide a GDPR text to advise users on privacy data
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="gdprUrl">
                            <Form.Label>Privacy Policy link url:</Form.Label>
                            <Form.Control
                                type="text"
                                name="gdprUrl"
                                value={config.gdpr.url}
                                placeholder='GDPR Url link'
                                onChange={handleInputChange}
                                required="required"
                            />
                            <Form.Control.Feedback type="invalid">
                                Provide an URL for the GDPR page to advise users on privacy data
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="pdfUrlLogo">
                            <Form.Label>Link or data: url to PDF logotype:</Form.Label>
                            <Form.Control
                                type="text"
                                name="pdfUrlLogo"
                                value={config.pdf ? config.pdf.urlLogo : ''}
                                placeholder='Url or data: logotype link'
                                onChange={handleInputChange}
                                required="required"
                            />
                            <Form.Control.Feedback type="invalid">
                                Provide an URL or data for logotype image. Example: /logo_civilio_trans.png
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="mapCenterLat">
                            <Form.Label>Map center lat:</Form.Label>
                            <Form.Control
                                type="text"
                                name="mapCenterLat"
                                value={config.map.center.lat}
                                placeholder='Latitude'
                                onChange={handleInputChange}
                                required="required"
                            />
                            <Form.Control.Feedback type="invalid">
                                Provide default lat coordinate to center the map
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="mapCenterLng">
                            <Form.Label>Map center lng:</Form.Label>
                            <Form.Control
                                type="text"
                                name="mapCenterLng"
                                value={config.map.center.lng}
                                placeholder='Longitude'
                                onChange={handleInputChange}
                                required="required"
                            />
                            <Form.Control.Feedback type="invalid">
                                Provide default lng coordinates to center the map
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="mapZoom">
                            <Form.Label>Map zoom:</Form.Label>
                            <Form.Control
                                type="text"
                                name="mapZoom"
                                value={config.map.zoom}
                                placeholder='Zoom'
                                onChange={handleInputChange}
                                required="required"
                            />
                            <Form.Control.Feedback type="invalid">
                                Provide default zoom for the map
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="mapScrollWheelZoom">
                            <Form.Label>Map scroll wheel zoom:</Form.Label>
                            <Form.Check
                                inline
                                label={config.map.scrollWheelZoom ? 'Yes' : 'No'}
                                name="mapScrollWheelZoom"
                                checked={config.map.scrollWheelZoom}
                                type="switch"
                                onChange={handleCheckBoxChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="mapMapboxToken">
                            <Form.Label>Mapbox token:</Form.Label>
                            <Form.Control
                                type="text"
                                name="mapMapboxToken"
                                value={config.map.mapboxToken}
                                placeholder='Mapbox token'
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                Provide Mapbox token to use Mapbox layers
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="sendmailActive">
                            <Form.Label>Sendmail active:</Form.Label>
                            <Form.Check
                                inline
                                label={config.sendmail.active ? 'Yes' : 'No'}
                                name="sendmailActive"
                                checked={config.sendmail.active}
                                type="switch"
                                onChange={handleCheckBoxChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="sendmailFromName">
                            <Form.Label>From name:</Form.Label>
                            <Form.Control
                                type="text"
                                name="sendmailFromName"
                                value={config.sendmail.fromName}
                                placeholder='System name'
                                onChange={handleInputChange}
                                disabled={!sendmailActive && ("disabled")}
                            />
                            <Form.Control.Feedback type="invalid">
                                Provide a from name for mails
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="sendmailFromEmail">
                            <Form.Label>From E-mail:</Form.Label>
                            <Form.Control
                                type="text"
                                name="sendmailFromEmail"
                                value={config.sendmail.fromEmail}
                                placeholder='System email'
                                onChange={handleInputChange}
                                disabled={!sendmailActive && ("disabled")}
                            />
                            <Form.Control.Feedback type="invalid">
                                Provide a from email for mails
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="sendmailSmtpHost">
                            <Form.Label>SMTP Host:</Form.Label>
                            <Form.Control
                                type="text"
                                name="sendmailSmtpHost"
                                value={config.sendmail.smtpHost}
                                placeholder='mail.yourhostname.com'
                                onChange={handleInputChange}
                                disabled={!sendmailActive && ("disabled")}
                            />
                            <Form.Control.Feedback type="invalid">
                                Provide a SMTP Host to send mails
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="sendmailSmtpPort">
                            <Form.Label>SMTP Port:</Form.Label>
                            <Form.Control
                                type="text"
                                name="sendmailSmtpPort"
                                value={config.sendmail.smtpPort}
                                placeholder='587'
                                onChange={handleInputChange}
                                disabled={!sendmailActive && ("disabled")}
                            />
                            <Form.Control.Feedback type="invalid">
                                Provide a SMTP Port to send mails
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="sendmailSmtpSecure">
                            <Form.Label>SMTP Secure:</Form.Label>
                            <Form.Check
                                inline
                                label={config.sendmail.smtpSecure ? 'Yes' : 'No'}
                                name="sendmailSmtpSecure"
                                checked={config.sendmail.smtpSecure}
                                type="switch"
                                onChange={handleCheckBoxChange}
                                disabled={!sendmailActive && ("disabled")}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="sendmailSmtpUser">
                            <Form.Label>SMTP User:</Form.Label>
                            <Form.Control
                                type="text"
                                name="sendmailSmtpUser"
                                value={config.sendmail.smtpUser}
                                placeholder='SMTP User account'
                                onChange={handleInputChange}
                                disabled={!sendmailActive && ("disabled")}
                            />
                            <Form.Control.Feedback type="invalid">
                                Provide a SMTP User account to send mails
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="sendmailSmtpPassword">
                            <Form.Label>SMTP Password:</Form.Label>
                            <Form.Control
                                type="password"
                                name="sendmailSmtpPassword"
                                value={smtpPassword}
                                placeholder='SMTP Password'
                                onChange={handleInputChange}
                                disabled={!sendmailActive && ("disabled")}
                            />
                            <Form.Control.Feedback type="invalid">
                                Provide a SMTP Password to send mails
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Button
                            className="btn btn-primary"
                            type="submit"
                        >
                            <i className="bi bi-save2"></i> Save system config
                        </Button>

                    </Form>
                </Col>
                <Col className='bg-light'>
                    <p>
                        <strong><i className="bi bi-hdd-fill"></i> Max size:</strong> {config.disk.usageMaxSize}Mb
                        /
                        <strong><i className="bi bi-database-fill"></i> Usage:</strong> {config.disk.usageSize}Mb
                    </p>
                    <ProgressBar variant='info' now={(config.disk.usageSize * 100 / config.disk.usageMaxSize)} label={`${config.disk.usageSize}Mb`} style={{ width: '300px', margin: '20px' }} />
                    <p>
                        <strong><i className="bi bi-calendar-fill"></i> Updated:</strong>{" "}
                        {moment(config.changedAt).fromNow()}
                    </p>
                    <p>
                        <strong><i className="bi bi-calendar-fill"></i> Created:</strong>{" "}
                        {moment(config.createdAt).fromNow()}
                    </p>

                    <p>
                        <strong><i className="bi bi-database-fill"></i> Collections:</strong>
                    </p>
                    <div className='p-2'>
                        {Object.keys(config.collections).map(key => (
                            <p key={'collection-' + key}>
                                <strong><i className="bi bi-collection"></i> {key}:</strong>{" "}
                                {config.collections[key].count} / <i className="bi bi-hdd"></i> {config.collections[key].totalSize}Mb
                            </p>
                        ))}
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Config