import moment from 'moment'

class EventStatsDataSet {
    /**
     * Creates an instance of ChatRoomSocketServices.
     * @param {Object} options - Options for the service.
     * @param {Object} options.eventData - The event data.
     */
    constructor() {
        this.eventId = null
        this.totalGroups = 0
        this.totalEntities = 0
        this.totalUsers = 0
        this.totalTasks = 0
        this.totalResults = 0
        this.lastUpdated = Date.now()
        this.groupLabels = []
        this.usersTaksByDay = []
    }

    // Set eventData
    setData = ({ eventId, data, usersTasksByDay }) => {
        this.eventId = eventId
        this.eventData = data
        this.usersTaksByDay = usersTasksByDay
        this.calculateTotals()
    }

    calculateTotals = () => {
        this.totalGroups = this.getTotalGroups()
        this.totalEntities = this.getUniqueEntities()
        this.totalUsers = this.getUniqueUsers()
        this.totalTasks = this.getTotalTasks()
        this.totalResults = this.getTotalResults()
        this.lastUpdated = this.getMostRecentDocument()
        this.groupLabels = this.getGroupsLabels()
    }

    // Get total event groups
    getTotalGroups = () => this.eventData.length


    // Get unique event groups users
    getUniqueUsers = () => {
        const uniqueIds = this.eventData.reduce((users, group) => users.concat(group.usersIds), [])
        return [...new Set(uniqueIds)].length
    }

    // Get unique event entities
    getUniqueEntities = () => {
        const uniqueIds = this.eventData.reduce((entities, group) => entities.concat(group.entitiesIds), [])
        return [...new Set(uniqueIds)].length
    }

    // Get total tasks 
    getTotalTasks = () => this.eventData.reduce((sum, group) => sum + group.totalTasks, 0)

    // Get total tasks by status
    getTotalTasksByStatus = (status) => {
        return this.eventData.reduce((sum, group) => sum + (group[`totalStatus_${status.toUpperCase()}`] || 0), 0)
    }

    // Get total results
    getTotalResults = () => this.eventData.reduce((sum, group) => sum + group.totalResults, 0)

    // Get total results by color
    getTotalResultsByColor = (color) => {
        return this.eventData.reduce((sum, group) => sum + (group[`totalResult_${color.toUpperCase()}`] || 0), 0)
    }

    // Get most recent date from Tasks
    getMostRecentDocument = () => {
        if (!Array.isArray(this.eventData) || this.eventData.length === 0) {
            return null
        }
        const sortedData = [...this.eventData].sort((a, b) =>
            moment(b.lastUpdated).diff(moment(a.lastUpdated))
        )
        return sortedData[0].lastUpdated
    }

    // Get group labels
    getGroupsLabels = () => {
        //return this.eventData.map((group) => group.groupName + ' #' + group.groupSkill)
        return this.eventData.map((group) => group.groupName)
    }

    // Get group tasks dataset
    getGroupTasksDataset = (key, label, backgroundColor) => ({
        label,
        data: this.eventData.map((group) => group[key]),
        backgroundColor,
    })

    // Get group users/entities dataset
    getGroupUsersDataset = (key, label, backgroundColor) => ({
        label,
        data: this.eventData.map((group) => group[key].length),
        backgroundColor,
    })

    // Get RADAR datasets
    getRadarDataset = () => {
        const data = [
            this.totalGroups,
            this.totalEntities,
            this.totalUsers,
            this.totalTasks,
            this.totalResults,
        ]

        return ([{
            label: '#',
            data: data,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
        }])
    }

    // Get tasks status datasets
    getTasksStatusDataset = () => {
        const data = [
            this.getTotalTasksByStatus('PENDING'),
            this.getTotalTasksByStatus('IN_PROGRESS'),
            this.getTotalTasksByStatus('COMPLETED'),
            this.getTotalTasksByStatus('CANCELLED')
        ]

        const colors = [
            'orange',
            'aqua',
            'blue',
            'lightslategray'
        ]

        return ([{
            label: '#',
            data: data,
            backgroundColor: colors,
            borderColor: colors,
            borderWidth: 0
        }])
    }

    // Get tasks result datasets
    getTasksResultDataset = () => {
        const data = [
            this.getTotalResultsByColor('GREEN'),
            this.getTotalResultsByColor('YELLOW'),
            this.getTotalResultsByColor('RED'),
            this.getTotalResultsByColor('BLACK'),
            this.getTotalResultsByColor('NONE')
        ]

        const colors = [
            'green',
            'yellow',
            'red',
            'black',
            'thistle'
        ]

        return ([{
            label: '#',
            data: data,
            backgroundColor: colors,
            borderColor: colors,
            borderWidth: 0
        }])
    }

    // Get users & tasks by days
    getUsersTasksByDay = (textUsers, textTasks, textPendingTasks) => {
        const labels = this.usersTaksByDay.map(item => item.day) // Extrae las etiquetas (días)
        const usersData = this.usersTaksByDay.map(item => item.usersCount) // Extrae los usuarios por día
        const tasksData = this.usersTaksByDay.map(item => item.tasksCount) // Extrae las tareas finalizadas por día
        const pendingTasksData = this.usersTaksByDay.map(item => item.pendingTasksCount) // Extrae las tareas pendientes por día

        return {
            labels: labels,
            datasets: [
                {
                    label: textUsers,
                    data: usersData,
                    borderColor: 'rgb(249, 207, 53)',
                    backgroundColor: 'rgba(249, 207, 53, 0.5)',
                    yAxisID: 'y',
                },
                {
                    label: textTasks,
                    data: tasksData,
                    borderColor: 'rgb(0, 0, 255)',
                    backgroundColor: 'rgba(0, 0, 255, 0.5)',
                    yAxisID: 'y',
                },
                {
                    label: textPendingTasks,
                    data: pendingTasksData,
                    borderColor: 'rgb(0, 255, 255)',
                    backgroundColor: 'rgba(0, 255, 255, 0.5)',
                    yAxisID: 'y',
                },
            ],
        }
    }

}

export default EventStatsDataSet