import React, { useEffect, useState, useRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import TableList from "../components/tables/TableList"

import ConfigService from 'services/ConfigService'
import EntitiesService from 'services/EntitiesService'

import EntitiesForm from './EntitiesForm'

import SearchBar from 'components/bars/SearchBar'

import { AlertError, AlertLoading } from 'helpers/AlertHelper'

const Entities = (props) => {
  const intl = useIntl()

  const fronConfig = ConfigService.getFrontConfig()


  const [keys, setKeys] = useState({})
  const [entities, setEntities] = useState([])
  const [pagination, setPagination] = useState([])
  const [currPage, setCurrPage] = useState(1)
  const [search, setSearch] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(false)

  const goToPage = (pageNum) => {
    setCurrPage(pageNum)
  }

  const refreshData = () => {
    setIsLoaded(false)
    getData()
  }

  const getData = async () => {
    await EntitiesService.getEntities(currPage, search, fronConfig.app.itemsListLimit).then(
      response => {
        setKeys({
          name: <FormattedMessage id="entitiesName" defaultMessage="Name" />,
          linkCode: <FormattedMessage id="entitiesLinkCode" defaultMessage="Link code" />,
          skills: <FormattedMessage id="entitiesSkills" defaultMessage="Skills" />,
          email: <FormattedMessage id="entitiesEmail" defaultMessage="E-mail" />,
          phone: <FormattedMessage id="entitiesPhoneNumber" defaultMessage="Phone Number" />,
          createdAt: <FormattedMessage id="entitiesCreatedAt" defaultMessage="Created at" />
        })
        setEntities(response.data.entities)
        setPagination(response.data.pagination)
        setIsLoaded(true)
      },
      error => {
        setError(error)
      }
    )

  }

  const prevSearch = useRef()
  useEffect(() => {
    if (!prevSearch.current || prevSearch.current !== search) {
      prevSearch.current = search
      setIsLoaded(false)
    }
    setCurrPage(1)
    getData()
  }, [search])

  const prevCurrentPage = useRef()
  useEffect(() => {
    if (!prevCurrentPage.current || prevCurrentPage.current !== currPage) {
      prevCurrentPage.current = currPage
      setIsLoaded(false)
    }
    getData()
  }, [currPage])

  if (error) {
    return (
      <AlertError error={error} />
    )
  } else if (!isLoaded && search=== '') {
    return (
      <AlertLoading isLoaded={isLoaded} />
    )
  }

  return (
    <>
      <SearchBar searchValue={search} setSearchValue={setSearch} />

      <TableList
        caption={intl.formatMessage({ id: 'entities', defaultMessage: 'Entities' })}
        icon="bi-briefcase-fill"
        data={entities}
        keys={keys}
        pagination={true}
        paginationData={pagination}
        goToPageFunction={goToPage}
        refreshData={refreshData}
        //setSearchValue={setSearch}
        //searchValue={search}
        titleAdd={intl.formatMessage({ id: 'entitiesAdd', defaultMessage: 'Add entity' })}
        titleEdit={intl.formatMessage({ id: 'entitiesEdit', defaultMessage: 'Edit entity' })}
        titleDelete={intl.formatMessage({ id: 'entitiesDelete', defaultMessage: 'Delete entity' })}
        ElementForm={EntitiesForm}
      />
    </>
  )

}

export default Entities