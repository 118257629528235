import { useEffect, useState, useRef } from "react"
import { useMap, LayerGroup, FeatureGroup, GeoJSON, Popup } from "react-leaflet"
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'

import styles from "./inspectionsButton.module.css"
import L from "leaflet"

import moment from 'moment'

import { taskStatusProperties, taskResultProperties, taskSearchProperties } from 'helpers/Tasks'
import { markerIcon } from "helpers/Markers"

const InspectionsButton = ({ tasks }) => {

    const map = useMap()
    const inspectionsLayerRef = useRef()
    const inspectionFeatureRef = useRef()
    const inspectionGeoLayerRef = useRef()

    const [showInspections, setShowInspections] = useState(false)
    const [tasksToShow, setTasksToShow] = useState(tasks)

    const handleFeatureMouseOver = (geoJ, featureColor) => {
        //if (!taskGeoLayerRef.current) return

        if (geoJ.layer.feature.geometry.type !== "Point") {
            geoJ.layer.setStyle({ color: "black", fillColor: featureColor, fillOpacity: 1, dashArray: 0 })
        } else {
            geoJ.layer.getElement().querySelector('path').style.fill = featureColor
        }
    }

    const handleFeatureMouseOut = (geoJ, featureColor) => {
        //if (!taskGeoLayerRef.current) return

        if (geoJ.layer.feature.geometry.type !== "Point") {
            geoJ.layer.setStyle({ color: 'black', fillColor: featureColor, fillOpacity: 1, dashArray: 3 })
        } else {
            geoJ.layer.getElement().querySelector('path').style.fill = featureColor
        }
    }

    useEffect(() => {
        if (!map) return
        // create custom button
        const customControl = L.Control.extend({
            // button position
            options: {
                position: "topright",
                className: `${styles.inspectionsButton} leaflet-bar`,
                html: '<i class="bi bi-clipboard2-check-fill"></i>',
                style: "width: 34px; height: 34px; left: 0; margin-top: 0; display: flex; cursor: pointer; justify-content: center; font-size: 1rem;",
            },

            // method
            onAdd: function (map) {
                this._map = map
                const button = L.DomUtil.create("div")
                L.DomEvent.disableClickPropagation(button)

                button.title = "Display inspections results"
                button.innerHTML = this.options.html
                button.className = this.options.className
                button.setAttribute("style", this.options.style)

                L.DomEvent.on(button, "click", this._clicked, this)

                return button
            },
            _clicked: function (e) {
                L.DomEvent.stopPropagation(e)

                const inspectionsBtnActive = document.querySelector(`.${styles.inspectionsButton}`)
                const inspectionsBtn = inspectionsBtnActive.classList.contains(styles.inspectionsActive)
                // add/remove class from inspections button
                inspectionsBtnActive.classList[inspectionsBtn ? "remove" : "add"](styles.inspectionsActive)

                // Click funtion
                ///////////////////////////
                setShowInspections(!inspectionsBtn)

                return
            }
        });

        // adding new button to map controll
        map.addControl(new customControl())
    }, [map])


    useEffect(() => {
        setTasksToShow(tasks)
    }, [tasks])

    return (
        map && showInspections && tasksToShow && (
            <>
                <LayerGroup
                    ref={inspectionsLayerRef}
                    attribution="Civilio inspections results"
                    color="red"
                    key={"lg-inspection-results"}
                    eventHandlers={{
                        /*
                                            add: (layerG) => {
                                                //getData(layerG)
                                                //map.fitBounds(layerGroupRef.current._map.getBounds());
                                                //setDataDownloaded(true)
                                            },
                                            remove: (layerG) => {
                                                //deleteData(layerG)
                                                //setDataDownloaded(false)
                                            }
                                            */
                    }}
                >
                    {tasksToShow && tasksToShow.map((inspection, index) => {

                        inspection.name = inspection.feature.name
                        inspection.feature.type = "Feature"
                        inspection.feature.statusProps = taskStatusProperties(inspection.status)
                        inspection.feature.resultProps = taskResultProperties(inspection.result, inspection.status)
                        return (
                            <FeatureGroup
                                ref={inspectionFeatureRef}
                                color="red"
                                key={"lg-inspection-fg-" + inspection._id}
                            >
                                <Popup>
                                    <h6><small><i className="bi bi-hash"></i> {inspection.feature.ref}</small></h6>
                                    <h6>{inspection.feature.name}</h6>
                                    {inspection.feature.properties && inspection.feature.properties['_message_'] &&
                                        <p><i className="bi bi-exclamation-triangle-fill"></i> {inspection.feature.properties['_message_']}</p>
                                    }
                                    {inspection.feature.properties && inspection.feature.properties['_document_'] &&
                                        <p className="text-center">
                                            <Button variant="outline-primary" type="button" href={inspection.feature.properties['_document_']} target={'_documentPDF'} size="sm">
                                                <i className="bi bi-file-earmark-richtext"></i> Documents
                                            </Button>
                                        </p>
                                    }

                                </Popup>

                                <GeoJSON
                                    ref={inspectionGeoLayerRef}
                                    key={"lg-inspection-fg-feature-" + inspection._id}
                                    data={inspection.feature}
                                    weight={2}
                                    opacity={1}
                                    color={"black"}
                                    dashArray={3}
                                    fillOpacity={1}
                                    fillColor={inspection.feature.resultProps.fillcolor}
                                    eventHandlers={{
                                        add: (geoJ) => {
                                        },
                                        remove: (geoJ) => {
                                        },
                                        mouseover: (geoJ) => {
                                            handleFeatureMouseOver(geoJ, 'black')
                                        },
                                        mouseout: (geoJ) => {
                                            handleFeatureMouseOut(geoJ, inspection.feature.resultProps.fillcolor)
                                        }
                                    }}
                                    pointToLayer={function (geoJsonPoint, latlng) {
                                        return L.marker(latlng, { icon: markerIcon(geoJsonPoint.resultProps.fillcolor) })
                                    }}
                                />
                            </FeatureGroup>
                        )


                    }
                    )}
                </LayerGroup>
            </>
        )
    )
}

export default InspectionsButton