import React, { useState, useEffect } from 'react'
import { Button, Modal, ListGroup, Badge } from 'react-bootstrap'

import moment from 'moment'

import { isUrlValid } from '../../utils/ValidationForm'

const InfoModal = ({ task, group, callback }) => {

    let documentLink = false

    const [show, setShow] = useState(false)
    const [fullscreen, setFullscreen] = useState(true)

    const handleShow = () => setShow(true)
    const handleClose = () => {
        setShow(false)
        callback(false)
    }

    useEffect(() => {
        handleShow()
    }, [])

    return (
        show && (
            <Modal show={show} fullscreen={fullscreen} onHide={handleClose} >
                <Modal.Header closeButton={false} className={'p-2 bg-' + task.resultProperties.bgcolor} style={{ color: task.resultProperties.textcolor }}>
                    <Button size={'sm'} onClick={handleClose} variant={task.resultProperties.bgcolor}><i className="bi bi-arrow-left h4"></i></Button>
                    <Modal.Title style={{ color: task.resultProperties.textcolor }} className="h6 d-flex justify-content-between align-items-center">
                        <i className="bi bi-info-square-fill"></i>&nbsp;{task.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-0'>
                    <ListGroup variant="flush">
                        <ListGroup.Item variant="light"><i className="bi bi-calendar3"></i> {moment(task.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</ListGroup.Item>
                        <ListGroup.Item variant="light"><i className="bi bi-calendar-event"></i> {group.event.name}</ListGroup.Item>
                        <ListGroup.Item variant="light"><i className="bi bi-diagram-3-fill"></i> {group.name}</ListGroup.Item>
                        <ListGroup.Item variant="light"><i className="bi bi-file-code-fill"></i> {group.form.title}</ListGroup.Item>
                        <ListGroup.Item variant="light"><i className="bi bi-lightning-fill"></i> {task.mission.name}</ListGroup.Item>
                        <ListGroup.Item variant="light"><i className="bi bi-layers-half"></i> {task.feature.layer}</ListGroup.Item>
                        <ListGroup.Item variant="light"><i className="bi bi-list-ul"></i> Properties: <br />
                            <ul>
                                {task.feature.properties && Object.keys(task.feature.properties).map((keyName, i) => {
                                    if (!keyName.startsWith('_')) {
                                        if (task.feature.properties[keyName] !== null && task.feature.properties[keyName] !== "") {
                                            if (isUrlValid(task.feature.properties[keyName])) {
                                                return (
                                                    <li key={"task-property-li-" + i}>
                                                        <span className="input-label"><b>{keyName}</b>: <a href={task.feature.properties[keyName]} target={"blank"}>Open link</a></span>
                                                    </li>
                                                )
                                            } else {
                                                return (
                                                    <li key={"task-property-li-" + i}>
                                                        <span className="input-label"><b>{keyName}</b>: {task.feature.properties[keyName]}</span>
                                                    </li>
                                                )
                                            }
                                        }
                                    } else {
                                        if (keyName === "_document_") {
                                            documentLink = task.feature.properties[keyName]
                                            return null
                                        }
                                    }
                                })}
                            </ul>
                        </ListGroup.Item>
                        <ListGroup.Item variant="light" className="d-flex justify-content-end">
                            <span className="me-auto">
                                <Badge bg={task.statusProperties.bgcolor}><i className={task.statusProperties.icon}></i> {task.statusProperties.text}</Badge>
                            </span>
                            {moment(task.statusChangedAt).format('MMMM Do YYYY, h:mm:ss a')}
                        </ListGroup.Item>
                        <ListGroup.Item variant="light" className="d-flex justify-content-end">
                            <span className="me-auto">
                                <Badge bg={task.resultProperties.bgcolor}><i className={task.resultProperties.icon}></i> {task.resultProperties.text}</Badge>
                            </span>
                            {moment(task.resultChangedAt).format('MMMM Do YYYY, h:mm:ss a')}
                        </ListGroup.Item>
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center align-items-center p-0">
                    {documentLink &&
                        <Button variant="primary" href={documentLink} target={'_documentPDF'}>
                            <i className="bi bi-file-earmark-richtext"></i> Documents
                        </Button>
                    }
                </Modal.Footer>
            </Modal >
        )
    )
}

export default InfoModal