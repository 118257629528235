import React, { useEffect, useState, useRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import TableList from "../components/tables/TableList"

import ConfigService from 'services/ConfigService'
import FormsService from 'services/FormsService'

import SearchBar from 'components/bars/SearchBar'

import FormsForm from './FormsForm'

import { AlertError, AlertLoading } from 'helpers/AlertHelper'

const Forms = (props) => {
  const intl = useIntl()

  const fronConfig = ConfigService.getFrontConfig()

  const [keys, setKeys] = useState({})
  const [forms, setForms] = useState([])
  const [pagination, setPagination] = useState([])
  const [currPage, setCurrPage] = useState(1)
  const [search, setSearch] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(false)

  const goToPage = (pageNum) => {
    setCurrPage(pageNum)
  }

  const refreshData = () => {
    setIsLoaded(false)
    getData()
  }

  const getData = async () => {
    await FormsService.getForms(currPage, search, fronConfig.app.itemsListLimit).then(
      response => {
        setKeys({
          title: <FormattedMessage id="formsTitle" defaultMessage="Title" />,
          skill: <FormattedMessage id="formsSkill" defaultMessage="Skill" />,
          createdAt: <FormattedMessage id="formsCreatedAt" defaultMessage="Created at" />
        })
        setForms(response.data.forms)
        setPagination(response.data.pagination)
        setIsLoaded(true)
      },
      error => {
        setError(error)
      }
    )

  }

  const prevSearch = useRef()
  useEffect(() => {
    if (!prevSearch.current || prevSearch.current !== search) {
      prevSearch.current = search
      setIsLoaded(false)
    }
    setCurrPage(1)
    getData()
  }, [search])

  const prevCurrentPage = useRef()
  useEffect(() => {
    if (!prevCurrentPage.current || prevCurrentPage.current !== currPage) {
      prevCurrentPage.current = currPage
      setIsLoaded(false)
    }
    getData()
  }, [currPage])

  if (error) {
    return (
      <AlertError error={error} />
    )
  } else if (!isLoaded && search === '') {
    return (
      <AlertLoading isLoaded={isLoaded} />
    )
  }

  return (
    <>
      <SearchBar searchValue={search} setSearchValue={setSearch} />

      <TableList
        caption={intl.formatMessage({ id: 'forms', defaultMessage: 'Forms' })}
        icon="bi-file-code-fill"
        data={forms}
        keys={keys}
        pagination={true}
        paginationData={pagination}
        goToPageFunction={goToPage}
        refreshData={refreshData}
        titleAdd={intl.formatMessage({ id: 'formsAdd', defaultMessage: 'Add form' })}
        titleEdit={intl.formatMessage({ id: 'formsEdit', defaultMessage: 'Edit form' })}
        titleDelete={intl.formatMessage({ id: 'formsDelete', defaultMessage: 'Delete form' })}
        ElementForm={FormsForm}
      />
    </>
  )
}

export default Forms