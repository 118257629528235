import { useEffect } from "react"
import { Row, Col, Container } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'
import ListGroup from 'react-bootstrap/ListGroup'
import Alert from 'react-bootstrap/Alert'
import { useIntl, FormattedMessage } from 'react-intl'

import moment from 'moment'

import DoughnutChart from 'components/charts/DoughnutChart'
import ChartColsCard from "components/card/ChartColsCard"

const GroupsList = ({ selectedGroupId, setSelectedGroupId, groupsList, groupNumTasks, taskList }) => {
    const intl = useIntl()

    const handleSelectGroup = (val) => {
        setSelectedGroupId(val)
    }

    const getTasksResultDataset = (data) => {
        // Función para contar ocurrencias de cada resultado
        const getTotalResultsByColor = (color) => {
            return data.filter(item => item.result === color).length
        }

        // Calculamos los totales por cada color
        const totals = [
            getTotalResultsByColor('GREEN'),
            getTotalResultsByColor('YELLOW'),
            getTotalResultsByColor('RED'),
            getTotalResultsByColor('BLACK'),
            getTotalResultsByColor('NONE')
        ]

        const colors = [
            'green',
            'yellow',
            'red',
            'black',
            'thistle'
        ]

        return [{
            label: '#',
            data: totals,
            backgroundColor: colors,
            borderColor: colors,
            borderWidth: 0
        }]
    }

    const datasetsTasksResults = () => {
        const labels = [
            intl.formatMessage({ id: "tasksResultGreen", defaultMessage: "Green" }),
            intl.formatMessage({ id: "tasksResultYellow", defaultMessage: "Yellow" }),
            intl.formatMessage({ id: "tasksResultRed", defaultMessage: "Red" }),
            intl.formatMessage({ id: "tasksResultBlack", defaultMessage: "Black" }),
            intl.formatMessage({ id: "tasksResultNone", defaultMessage: "None" })
        ]
        return { labels, datasets: getTasksResultDataset(taskList) }
    }

    const formatPercent = (num, total) => {
        if (typeof num !== 'number' || typeof total !== 'number' || total === 0) {
            return <>0 <small>(0%)</small></>
        }

        const percent = ((num / total) * 100).toFixed(2)

        return <>{num} <small>({percent}%)</small></>
    }

    const taskResultContent = (data) => {
        /// Función para contar ocurrencias de cada resultado
        const getTotalResultsByColor = (color) => {
            return data.filter(item => item.result === color).length
        }

        // Calculamos los totales por cada color
        const totals = [
            getTotalResultsByColor('GREEN'),
            getTotalResultsByColor('YELLOW'),
            getTotalResultsByColor('RED'),
            getTotalResultsByColor('BLACK'),
            getTotalResultsByColor('NONE')
        ]

        return (
            [
                [{
                    icon: <i className="bi bi-house-check-fill"></i>,
                    title: <FormattedMessage id="tasksResultGreen" defaultMessage="Green" />,
                    text: totals[0] ? formatPercent(totals[0], data.length) : '0 (0%)',
                    color: 'green',
                    numberAlign: 'center'
                },
                {
                    icon: <i className="bi bi-house-exclamation-fill"></i>,
                    title: <FormattedMessage id="tasksResultYellow" defaultMessage="Yellow" />,
                    text: totals[1] ? formatPercent(totals[1], data.length) : '0 (0%)',
                    color: 'yellow',
                    numberAlign: 'center'
                }],
                [{
                    icon: <i className="bi bi-house-dash-fill"></i>,
                    title: <FormattedMessage id="tasksResultRed" defaultMessage="Red" />,
                    text: totals[2] ? formatPercent(totals[2], data.length) : '0 (0%)',
                    color: 'red',
                    numberAlign: 'center'
                },
                {
                    icon: <i className="bi bi-house-x-fill"></i>,
                    title: <FormattedMessage id="tasksResultBlack" defaultMessage="Black" />,
                    text: totals[3] ? formatPercent(totals[3], data.length) : '0 (0%)',
                    color: 'black',
                    numberAlign: 'center'
                }],
                [{
                    icon: <i className="bi bi-house-check-fill"></i>,
                    title: <FormattedMessage id="tasksResultNone" defaultMessage="None" />,
                    text: totals[4] ? formatPercent(totals[4], data.length) : '0 (0%)',
                    color: 'thistle',
                    numberAlign: 'center'
                }]
            ]
        )
    }

    useEffect(() => {
        if (!selectedGroupId) return
    }, [selectedGroupId])

    return (
        <div style={{ marginBottom: 40 }}>
            {groupsList && groupsList.length > 0 ? (
                <>
                    <h5 className="bg-light p-1"><i className='bi bi-shield-fill'></i> <FormattedMessage id="statsInterventionGroups" defaultMessage="Intervention Groups" /></h5>
                    <Accordion flush activeKey={selectedGroupId} onSelect={handleSelectGroup}>
                        {groupsList.map((group) => {
                            return (
                                <Accordion.Item key={'accordion-group-' + group._id} eventKey={group._id}>
                                    <Accordion.Header>
                                        <i className='bi bi-shield'></i>&nbsp;{group.name}
                                    </Accordion.Header>
                                    <Accordion.Body className="p-0">
                                        <ListGroup variant="flush">
                                            <ListGroup.Item variant="light"><i className="bi bi-calendar3"></i> {moment(group.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</ListGroup.Item>
                                            <ListGroup.Item variant="light"><i className="bi bi-calendar-event"></i> {group.event.name}</ListGroup.Item>
                                            <ListGroup.Item variant="light"><i className="bi bi-diagram-3-fill"></i> {group.name}</ListGroup.Item>
                                            <ListGroup.Item variant="light"><i className="bi bi-file-code-fill"></i> {group.form.title}</ListGroup.Item>
                                            <ListGroup.Item variant="light">
                                                <Container>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <ChartColsCard
                                                                header={<h6><i className="bi bi-house-fill"></i> <FormattedMessage id="statsInspections" defaultMessage="Inspections" />: {groupNumTasks}</h6>}
                                                                content={taskResultContent(taskList)}
                                                                border={'light'}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="justify-content-center">
                                                        <Col xs={12} style={{ maxHeight: 300 }} className="d-flex justify-content-center align-items-center">
                                                            <DoughnutChart
                                                                data={datasetsTasksResults()}
                                                            //title={intl.formatMessage({ id: "statsInspectionsFinished", defaultMessage: "Finished inspections" })}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })}
                    </Accordion >
                </>
            ) : (
                <div className="container p-1">
                    <Alert variant={'warning'}>
                        No groups to show!
                    </Alert>
                </div>
            )}

        </div>
    )
}

export default GroupsList