import React, { useRef, useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import { FormattedMessage } from 'react-intl'

import moment from 'moment'

import { nestedToFlatten, flattenToNested } from 'helpers/JsonData.helper.js'

const InspectionModal = ({ task, group, callback }) => {
    const modalBodyRef = useRef()

    const [show, setShow] = useState(false)
    const [fullscreen, setFullscreen] = useState(true)
    const [taskSelected, setTaskSelected] = useState(task)


    const handleShow = () => setShow(true)
    const handleClose = () => {
        setShow(false)
        callback(false)
    }

    const [alertMsg, setAlertMsg] = useState(false)
    const [alertType, setAlertType] = useState(false)

    const [data, setData] = useState(task.formData && task.formData !== '' ? nestedToFlatten(JSON.parse(task.formData)) : {})
    const [result, setResult] = useState(task.result)
    const [resultDescription, setResultDescription] = useState(task.resultDescription)

    useEffect(() => {
        handleShow()
        setTaskSelected({
            ...task, // Propiedades previas de task
            group,   // Nueva propiedad o actualización del campo group
        })
    }, [])

    const lastUpdateDate = moment.max(
        moment(taskSelected.statusChangedAt),
        moment(taskSelected.resultChangedAt)
    ).toDate()

    const renderSchemaData = (schema, data, parentKey = '') => {
        const properties = schema.properties || {}
        const allOf = schema.allOf || []
    
        const mergedProperties = allOf.reduce((acc, item) => {
            if (item.if && item.then) {
                const conditionMet =
                    item.if.properties &&
                    Object.entries(item.if.properties).every(
                        ([conditionKey, conditionValue]) => data[conditionKey] === conditionValue.const
                    )
    
                acc = conditionMet ? { ...acc, ...item.then.properties } : acc
            } else if (item.properties) {
                acc = { ...acc, ...item.properties }
            }
    
            return acc
        }, { ...properties })
    
        return (
            <div>
                {Object.entries(mergedProperties).map(([key, fieldSchema]) => {
                    const value = data[key]
                    const fullKey = parentKey ? `${parentKey}.${key}` : key
    
                    if (typeof value === 'object' && fieldSchema) {
                        return (
                            <div
                                key={`schemaData-key-${fullKey}`}
                                style={{ borderBottom: '1px solid #ccc', marginBottom: '10px', paddingBottom: '10px' }}
                            >
                                <div>
                                    <h6>{fieldSchema.title || key}</h6>
                                </div>
                                <div>
                                    {renderSchemaData(fieldSchema, value, fullKey)}
                                </div>
                            </div>
                        )
                    } else if (value !== undefined) {
                        let valueStr = checkFeaturePropertiesValue(task.feature, value)
                        if (valueStr === 'false') {
                            valueStr = <FormattedMessage id="textNo" defaultMessage="No" />
                        }
                        if (valueStr === 'true') {
                            valueStr = <FormattedMessage id="textYes" defaultMessage="Yes" />
                        }
                        return (
                            <div
                                key={`schemaData-key-${fullKey}`}
                            >
                                <div>
                                    <strong>{fieldSchema ? fieldSchema.title || key : key}:</strong>
                                </div>
                                <div>{valueStr}</div>
                            </div>
                        )
                    }
    
                    return null
                })}
            </div>
        )
    }
    
    // En valores por defecto "default" si se usa $.properties.nombrePropiedad se cambia por el feature.properties.nombrePropiedad
    // En teoría debe hacerlo la APP cuando procesa el formulario para la toma de datos.
    const checkFeaturePropertiesValue = (feature, value) => {
        if (typeof value !== 'string' || !value) {
            return String(value) // Devolver value tal como es si no es una cadena o no está definido
        }

        return value.replace(/\$\.properties\.\w+/g, match => {
            const propertyName = match.substring(13) // Eliminar "$.properties." del principio

            // Verificar si task.feature y task.feature.properties están definidos
            if (feature && feature.properties && feature.properties[propertyName] !== undefined) {
                // Devolver el valor correspondiente si está definido
                return String(feature.properties[propertyName])
            } else {
                // Devolver la cadena original si no se encuentra
                return String(match)
            }
        })
    }

    return (
        show && data && (
            <>
                <Modal show={show} fullscreen={fullscreen}>
                    <Modal.Header closeButton={false} className={'p-2 bg-' + task.resultProperties.bgcolor} style={{ color: task.resultProperties.textcolor }}>
                        <Button size={'sm'} onClick={handleClose} variant={task.resultProperties.bgcolor}><i className="bi bi-arrow-left h4"></i></Button>
                        <Modal.Title style={{ color: task.resultProperties.textcolor }} className="h6 d-flex justify-content-between align-items-center">
                            <i className="bi bi-clipboard2-check-fill"></i>&nbsp;{task.name}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-2' ref={modalBodyRef}>
                        {alertMsg && alertType && <Alert variant={alertType}>{alertMsg}</Alert>}
                        <Form.Group className="mb-3">
                            <Form.Label><i className="bi bi-hash"></i> Reference:</Form.Label>
                            {task.feature.ref}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="taskform.task.createdAt">
                            <Form.Label><i className="bi bi-calendar3"></i> Created at:</Form.Label>
                            {moment(task.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="taskform.task.lastUpdateDate">
                            <Form.Label><i className="bi bi-calendar3"></i> Updated at:</Form.Label>
                            {moment(lastUpdateDate).format('MMMM Do YYYY, h:mm:ss a')}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label><i className="bi bi-person-check"></i> Inspector:</Form.Label>
                            {task.user.name}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label><i className="bi bi-envelope"></i> E-mail:</Form.Label>
                            {task.user.email}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label><i className="bi bi-phone"></i> Phone:</Form.Label>
                            {task.user.phone}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="taskform.task.result">
                            <Form.Label><h6><i className="bi bi-clipboard2-check-fill"></i> Inspection Result</h6></Form.Label>
                            <div className="btn-group btn-group-sm d-flex justify-content-center align-items-center" role="group">

                                <input disabled type="radio" className="btn-check" name="result" value="GREEN" id="resultRadio1" autoComplete="off" defaultChecked={result === "GREEN"} />
                                <label className="btn btn-outline-success" htmlFor="resultRadio1"><i className="bi bi-house-check-fill"></i> Green</label>

                                <input disabled type="radio" className="btn-check" name="result" value="YELLOW" id="resultRadio2" autoComplete="off" defaultChecked={result === "YELLOW"} />
                                <label className="btn btn-outline-warning" htmlFor="resultRadio2"><i className="bi bi-house-exclamation-fill"></i> Yellow</label>

                                <input disabled type="radio" className="btn-check" name="result" value="RED" id="resultRadio3" autoComplete="off" defaultChecked={result === "RED"} />
                                <label className="btn btn-outline-danger" htmlFor="resultRadio3"><i className="bi bi-house-slash-fill"></i> Red</label>

                                <input disabled type="radio" className="btn-check" name="result" value="BLACK" id="resultRadio4" autoComplete="off" defaultChecked={result === "BLACK"} />
                                <label className="btn btn-outline-dark" htmlFor="resultRadio4"><i className="bi bi-house-x-fill"></i> Black</label>
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Select a task status
                            </Form.Control.Feedback>
                        </Form.Group>

                        {task.formData ? renderSchemaData(group.form, JSON.parse(task.formData)) :
                            <FormattedMessage id="textBack" defaultMessage="Data not received yet!" />
                        }

                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center align-items-center p-0">
                        <Button variant="primary" onClick={handleClose}>
                            <i className="bi bi-arrow-left"></i> <FormattedMessage id="textBack" defaultMessage="Back" />
                        </Button>
                    </Modal.Footer>
                </Modal >
            </>
        )
    )
}

export default InspectionModal