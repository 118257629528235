import axios from 'axios'
import authHeader from './AuthHeader'
import { API_URL } from '../config/api'

class PicturesService {

    // Get pictures list from task :id 
    getPicturesOLD = async (id, dataUrl = false) => {
        return await axios.get(API_URL + '/tasks/' + id + '/pictures'+ '?dataUrl=' + dataUrl, { headers: authHeader() })
    }

    getPictures = async (id, dataUrl = false) => {
        const url = `${API_URL}/tasks/${id}/pictures?dataUrl=${dataUrl}`
        return await axios.get(url, { headers: authHeader() })
    }

    // Add new picture to task :id
    addPicture = async (id, picture) => {
        return await axios.post(API_URL + '/tasks/' + id + '/pictures', picture, { headers: authHeader() })
    }

    // Update picture :pictureId data from task :id
    updatePicture = async (id, pictureId, picture) => {
        return await axios.patch(API_URL + '/tasks/' + id + '/pictures/' + pictureId, picture, { headers: authHeader() })
    }

    // Get picture :pictureId from task :id
    getPicture = async (id, pictureId) => {
        return await axios.get(API_URL + '/tasks/' + id + '/pictures/' + pictureId, { headers: authHeader() })
    }

    // Delete picture :pictureId from task :id
    deletePicture = async (id, pictureId) => {
        return await axios.delete(API_URL + '/tasks/' + id + '/pictures/' + pictureId, { headers: authHeader() })
    }

}

export default new PicturesService()