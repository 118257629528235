import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'

import RJSForm from 'components/rjsf/RJSForm'
import HelpModal from 'components/modals/HelpModal'

const RJSFields = ({ task, data, onDataChange }) => {

    const schema = task.group.form
    const feature = task.feature

    const handleRJSFormChange = (e) => {
        onDataChange(e)
    }

    const [activeAccordionKey, setActiveAccordionKey] = useState(null)
    const handleAccordion = (e) => {
        setActiveAccordionKey(e)
    }

    const [showHelpModal, setShowHelpModal] = useState(false)
    const [helpInfo, setHelpInfo] = useState({ title: 'Help title', description: 'Help description' })
    const handleShowHelpModal = (help) => {
        setHelpInfo(help)
        setShowHelpModal(true)
    }
    const handleCloseHelpModal = () => setShowHelpModal(false)


    const generateFormFields = ({ schema, data, parentKey = '', feature }) => {
        const properties = schema.properties || {}
        const allOf = schema.allOf || []        
        /*
        const mergedProperties = allOf.reduce((acc, item) => {
            if (item.if && item.then) {
                const conditionMet =
                    item.if.properties &&
                    Object.entries(item.if.properties).every(([conditionKey, conditionValue]) => data[conditionKey] === conditionValue.const)

                acc = conditionMet ? { ...acc, ...item.then.properties } : acc
            } else if (item.properties) {
                acc = { ...acc, ...item.properties }
            }

            return acc
        }, { ...properties })
        */
        if (properties) {
            return (
                <Accordion flush onSelect={handleAccordion}>
                    {Object.entries(properties).map(([key, property]) => {
                        const inputName = parentKey ? `${parentKey}.${key}` : key
                        const inputValue = data?.[inputName] || null
                        let updatedProperty
                        if (property.default) {
                            updatedProperty = { ...property, default: checkFeaturePropertiesDefault({ feature, property }) }
                        } else {
                            updatedProperty = property
                        }
                        return (
                            <div key={"form-input-" + inputName}>
                                {updatedProperty.type === 'object' ? (
                                    <Accordion.Item eventKey={inputName} className='p-0'>
                                        <Accordion.Header>
                                            <h5>
                                                {activeAccordionKey === inputName ? (<i className="bi bi-folder2-open"></i>) : (<i className="bi bi-folder"></i>)}
                                                &nbsp;{updatedProperty.title}
                                            </h5>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {generateFormFields({ schema: updatedProperty, data: data, parentKey: inputName, feature: feature })}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ) : (
                                    <RJSForm name={inputName} schema={updatedProperty} formData={inputValue} onChange={handleRJSFormChange} helpInfo={handleShowHelpModal} />
                                )}
                            </div>
                        )
                    })}
                </Accordion>
            )
        }

        return null
    }

    const checkFeaturePropertiesDefault = ({ feature, property }) => {
        if (!property || typeof property.default !== 'string') {
            return property.default // Devolver property.default tal como es si no es una cadena o property no está definido
        }
        return property.default.replace(/\$\.properties\.\w+/g, match => {
            const propertyName = match.substring(13) // Eliminar "$.properties." del principio
            // Verificar si task.feature y task.feature.properties están definidos
            if (feature && feature.properties && feature.properties[propertyName] !== undefined) {
                // Devolver el valor correspondiente si está definido
                return feature.properties[propertyName]
            } else {
                // Devolver la cadena original si no se encuentra
                return match
            }
        })
    }

    return (
        <>
            {showHelpModal && helpInfo &&
                <HelpModal show={showHelpModal} helpInfo={helpInfo} onClose={handleCloseHelpModal} />
            }

            {generateFormFields({ schema, data, feature })}
        </>
    )
}

export default RJSFields
