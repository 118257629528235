import React, { useRef, useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import { FormattedMessage } from 'react-intl'

import moment from 'moment'

import TasksService from 'services/TasksService'

import RJSFields from 'components/rjsf/RJSFields'

import { nestedToFlatten, flattenToNested } from 'helpers/JsonData.helper.js'

const InspectionModal = ({ task, callback, updateTasksList }) => {
    const modalBodyRef = useRef()

    const [show, setShow] = useState(false)
    const [fullscreen, setFullscreen] = useState(true)

    const handleShow = () => setShow(true)
    const handleClose = () => {
        if (update) {
            updateTask(task.mission._id, task._id)
        }
        setShow(false)
        callback(false)
    }


    const [alertMsg, setAlertMsg] = useState(false)
    const [alertType, setAlertType] = useState(false)

    const [data, setData] = useState(task.formData && task.formData !== '' ? nestedToFlatten(JSON.parse(task.formData)) : {})
    const [result, setResult] = useState(task.result)
    const [resultDescription, setResultDescription] = useState(task.resultDescription)
    const [update, setUpdate] = useState(false)


    const scrollToTopModalBody = () => {
        if (modalBodyRef.current) {
            modalBodyRef.current.scrollTop = 0
        }
    }

    const handleResultRadioBtn = (e) => {
        setResult(e.target.value)
        setUpdate(true)
    }

    const handleResultDescriptionInput = (e) => {
        setResultDescription(e.target.value)
        setUpdate(true)
    }

    const handleUpdateBtn = (e) => {
        e.preventDefault()
        e.target.disabled = true
        scrollToTopModalBody()
        updateTask(task.mission._id, task._id)
        e.target.disabled = false
    }

    const updateTask = async (missionId, taskId) => {
        contentMessage("Updating task ...", "secondary")
        const update = { status: 'FINISHED', result: result, resultDescription: resultDescription, formData: JSON.stringify(flattenToNested(data)) }
        await TasksService.updateUserTask(taskId, update).then(
            response => {
                const updatedTask = response.data
                contentMessage("Task updated", "primary")
                updateTasksList(taskId, update)
                setUpdate(false)
            },
            error => {
                contentMessage('Error: ' + error.message, "danger")
            }
        )
    }

    const contentMessage = (msg, type) => {
        setAlertMsg(msg)
        setAlertType(type)
    }

    const handleData = (data) => {
        setData((prevData) => ({ ...prevData, [data.idSchema.$id]: data.formData }))
    }

    useEffect(() => {
        handleShow()
    }, [])

    return (
        show && data && (
            <>
                <Modal show={show} fullscreen={fullscreen}>
                    <Modal.Header closeButton={false} className={'p-2 bg-' + task.resultProperties.bgcolor} style={{ color: task.resultProperties.textcolor }}>
                        <Button size={'sm'} onClick={handleClose} variant={task.resultProperties.bgcolor}><i className="bi bi-arrow-left h4"></i></Button>
                        <Modal.Title style={{ color: task.resultProperties.textcolor }} className="h6 d-flex justify-content-between align-items-center">
                            <i className="bi bi-clipboard2-check-fill"></i>&nbsp;{task.name}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-2' ref={modalBodyRef}>
                        {alertMsg && alertType && <Alert variant={alertType}>{alertMsg}</Alert>}
                        <Form.Group className="mb-3">
                            <Form.Label><i className="bi bi-hash"></i> Reference</Form.Label>
                            {task.feature.ref}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="taskform.task.createdAt">
                            <Form.Label><i className="bi bi-calendar3"></i> Created at</Form.Label>
                            {moment(task.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="taskform.task.result">
                            <Form.Label><i className="bi bi-clipboard2-check-fill"></i> Inspection Result</Form.Label>
                            <div className="btn-group btn-group-sm d-flex justify-content-center align-items-center" role="group">

                                <input type="radio" className="btn-check" name="result" value="GREEN" id="resultRadio1" autoComplete="off" defaultChecked={result === "GREEN"} onChange={handleResultRadioBtn} />
                                <label className="btn btn-outline-success" htmlFor="resultRadio1"><i className="bi bi-house-check-fill"></i> Green</label>

                                <input type="radio" className="btn-check" name="result" value="YELLOW" id="resultRadio2" autoComplete="off" defaultChecked={result === "YELLOW"} onChange={handleResultRadioBtn} />
                                <label className="btn btn-outline-warning" htmlFor="resultRadio2"><i className="bi bi-house-exclamation-fill"></i> Yellow</label>

                                <input type="radio" className="btn-check" name="result" value="RED" id="resultRadio3" autoComplete="off" defaultChecked={result === "RED"} onChange={handleResultRadioBtn} />
                                <label className="btn btn-outline-danger" htmlFor="resultRadio3"><i className="bi bi-house-slash-fill"></i> Red</label>

                                <input type="radio" className="btn-check" name="result" value="BLACK" id="resultRadio4" autoComplete="off" defaultChecked={result === "BLACK"} onChange={handleResultRadioBtn} />
                                <label className="btn btn-outline-dark" htmlFor="resultRadio4"><i className="bi bi-house-x-fill"></i> Black</label>
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Select a task status
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="taskform.task.resultDescription">
                            <Form.Label><i className="bi bi-card-text"></i> Inspection description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={6}
                                name="resultDescription"
                                value={resultDescription}
                                onChange={handleResultDescriptionInput}
                            />
                            <Form.Control.Feedback type="invalid">
                                Write the inspection description
                            </Form.Control.Feedback>
                        </Form.Group>

                        <RJSFields task={task} data={data} onDataChange={handleData} />

                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center align-items-center p-0">
                        <Button variant="primary" onClick={handleUpdateBtn}>
                            <i className="bi bi-pencil-square"></i> <FormattedMessage id="textUpdate" defaultMessage="Update" />
                        </Button>
                    </Modal.Footer>
                </Modal >
            </>
        )
    )
}

export default InspectionModal