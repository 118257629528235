import React, { useState, useEffect } from 'react'
import { MapContainer } from "react-leaflet"
import { useMap, TileLayer, LayersControl, WMSTileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import L from 'leaflet'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import LocationButton from "components/map/controls/LocationButton"
import GetCoordinates from "components/map/controls/GetCoordinates"
import InspectionsButton from "./InspectionsButton"

import MapFeature from './MapFeature'

const basicLayersArray = [
    {
        type: "base",
        name: "OpenStreetMap",
        attribution: '&copy; <a target="_blank" href="http://openstreetmap.org">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        maxZoom: 19
    },
    {
        type: "base",
        name: "Sentinel2 PNOA MA",
        attribution: '&copy; <a target="_blank" href="https://www.scne.es/">Sistema Cartográfico Nacional de España</a> contributors',
        url: 'https://tms-pnoa-ma.idee.es/1.0.0/pnoa-ma/{z}/{x}/{-y}.jpeg',
        maxZoom: 19
    },
    {
        type: "wms",
        name: "SEDE Catastro",
        attribution: '&copy; <a href="https://www.sedecatastro.gob.es/" target="_blank">Dirección General de Catastro</a>',
        //url: 'https://ovc.catastro.meh.es/Cartografia/WMS/ServidorWMS.aspx',
        //layers: 'CATASTRO',
        //url: 'https://ovc.catastro.meh.es/cartografia/INSPIRE/spadgcwms.aspx',
        //layers: ['CP.CadastralParcel', 'CP.CadastralZoning', 'AD.Address', 'BU.Building', 'BU.BuildingPart'],
        //layers: ['CP.CadastralParcel', 'BU.Building'],
        //https://www1.sedecatastro.gob.es/cartografia/GeneraMapa.aspx?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=CATASTRO&SRS=EPSG%3A3857&CRS=EPSG%3A3857
        url: 'https://www1.sedecatastro.gob.es/cartografia/GeneraMapa.aspx',
        layers: 'CATASTRO',
        format: 'image/png',
        transparent: true,
        maxZoom: 20       
    },
    {
        type: "wms",
        name: "CARTOCIUDAD",
        attribution: '&copy; <a href="https://www.cartociudad.es/" target="_blank">Cartociudad</a>',
        url: 'https://www.cartociudad.es/wms-inspire/direcciones-ccpp?SERVICE=WMS&',
        layers: ['AD.Address', 'codigo-postal'],
        format: 'image/png',
        transparent: true,
        maxZoom: 20       
    }
]

const MapModal = ({ task, callback, tasks, mapCenter, mapZoom }) => {
    const [map, setMap] = useState(null)

    const [show, setShow] = useState(false)
    const [fullscreen, setFullscreen] = useState(true)


    const handleShow = () => setShow(true)
    const handleClose = () => {
        setShow(false)
        callback(false)
    }

    const [layersArray, setLayersArray] = useState(basicLayersArray)

    useEffect(() => {
        task.feature.type = 'Feature'
        handleShow()
    }, [])

    return (
        show && (
            <Modal show={show} fullscreen={fullscreen} onHide={handleClose} >
                <Modal.Header closeButton={false} className={'p-2 bg-' + task.resultProperties.bgcolor} style={{ color: task.resultProperties.textcolor }}>
                    <Button size={'sm'} onClick={handleClose} variant={task.resultProperties.bgcolor}><i className="bi bi-arrow-left h4"></i></Button>
                    <Modal.Title style={{ color: task.resultProperties.textcolor }} className="h6 d-flex justify-content-between align-items-center">
                        <i className="bi bi-map-fill"></i>&nbsp;{task.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-0'>
                    <MapContainer
                        key={"Feature-Map-" + task._id}
                        fullscreenControl={true}
                        whenCreated={setMap}
                        center={mapCenter}
                        zoom={mapZoom}
                        scrollWheelZoom={true}
                        style={{ height: '100%', width: '100%' }}
                        maxZoom={19}
                    >

                        <LayersControl position="topright" collapsed={true}>

                            {layersArray.map((layer, index) => {

                                switch (layer.type) {
                                    case 'base':
                                        return (
                                            <LayersControl.BaseLayer
                                                key={"lc-baselayer-" + index}
                                                checked={index === 0 ? true : false}
                                                name={layer.name}
                                            >
                                                <TileLayer
                                                    attribution={layer.attribution}
                                                    url={layer.url}
                                                    maxZoom={!layer.maxZoom ? 18 : layer.maxZoom}
                                                    minZoom={!layer.minZoom ? 1 : layer.minZoom}
                                                />
                                            </LayersControl.BaseLayer>
                                        )

                                    case 'wms':
                                        return (
                                            <LayersControl.Overlay
                                                key={"lc-overlay-" + index}
                                                checked={index === 0 ? true : false}
                                                name={layer.name}
                                            >
                                                <WMSTileLayer
                                                    attribution={layer.attribution}
                                                    url={layer.url}
                                                    transparent={!layer.transparent ? false : true}
                                                    format={layer.format}
                                                    layers={layer.layers}
                                                    maxZoom={!layer.maxZoom ? 18 : layer.maxZoom}
                                                    minZoom={!layer.minZoom ? 1 : layer.minZoom}
                                                    eventHandlers={{
                                                        add: (tile) => {
                                                            //console.log("Added WMS")
                                                        },
                                                        remove: (tile) => {
                                                            //console.log("Removed WMS")
                                                        }
                                                    }}
                                                />
                                            </LayersControl.Overlay>
                                        )


                                }
                            })}

                        </LayersControl>

                        <LocationButton />

                        <GetCoordinates />

                        <MapFeature task={task} />

                        <InspectionsButton tasks={tasks} />

                    </MapContainer>
                </Modal.Body>
            </Modal >
        )
    )
}


function LocationMarker() {
    const [position, setPosition] = useState(null);
    const [bbox, setBbox] = useState([]);

    const map = useMap();

    useEffect(() => {
        map.locate().on("locationfound", function (e) {
            setPosition(e.latlng);
            map.flyTo(e.latlng, map.getZoom());
            const radius = e.accuracy;
            const circle = L.circle(e.latlng, radius);
            circle.addTo(map);
            setBbox(e.bounds.toBBoxString().split(","));
        });
    }, [map]);

    return position === null ? null : (
        <Marker position={position}>
            <Popup>
                You are here. <br />
                Map bbox: <br />
                <b>Southwest lng</b>: {bbox[0]} <br />
                <b>Southwest lat</b>: {bbox[1]} <br />
                <b>Northeast lng</b>: {bbox[2]} <br />
                <b>Northeast lat</b>: {bbox[3]}
            </Popup>
        </Marker>
    );
}

export default MapModal