import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Carousel from 'react-bootstrap/Carousel'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/ListGroup'
import Alert from 'react-bootstrap/Alert'
import { FormattedMessage } from 'react-intl'

import Spinner from "../../components/spinner/Spinner"

import PicturesService from 'services/PicturesService'

const PhotosModal = ({ task, callback }) => {

    const [alertMsg, setAlertMsg] = useState(false)
    const [alertType, setAlertType] = useState(false)

    const [show, setShow] = useState(false)
    const [fullscreen, setFullscreen] = useState(true)

    const handleShow = () => setShow(true)
    const handleClose = () => {
        setShow(false)
        callback(false)

        const update = { pictures: images }
    }

    const [isLoaded, setIsLoaded] = useState(false)
    const [images, setImages] = useState([])

    const contentMessage = (msg, type) => {
        setAlertMsg(msg)
        setAlertType(type)
    }

    useEffect(() => {
        if (isLoaded) return

        PicturesService.getPictures(task._id).then(  // false to don't get picture dataUrl
            response => {
                const loadedImages = response.data
                setImages(loadedImages.map(obj => ({ ...obj, saved: true })))
                setIsLoaded(true)
            },
            error => {
                contentMessage('Error: ' + error.message, "danger")
            }
        )

        handleShow()
    }, [])

    return (
        show && (
            <>
                <Modal show={show} fullscreen={fullscreen} onHide={handleClose} >
                    <Modal.Header closeButton={false} className={'p-2 bg-' + task.resultProperties.bgcolor} style={{ color: task.resultProperties.textcolor }}>
                        <Button size={'sm'} onClick={handleClose} variant={task.resultProperties.bgcolor}><i className="bi bi-arrow-left h4"></i></Button>
                        <Modal.Title style={{ color: task.resultProperties.textcolor }} className="h6 d-flex justify-content-between align-items-center">
                            <i className="bi bi-camera-fill"></i>&nbsp;{task.name}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-2 bg-secondary'>
                        <>
                            {alertMsg && alertType && <Alert variant={alertType}>{alertMsg}</Alert>}
                        </>
                        {isLoaded ? (
                            <Carousel>
                                {
                                    images.map((picture) => {
                                        return (
                                            <Carousel.Item key={'picture-show' + picture.id}>
                                                <img
                                                    src={'/api/picture/' + picture.tokenUrl + '/?imgWidth=800'}
                                                    alt={picture.title}
                                                    width="100%" // Asegura que la imagen ocupe todo el ancho disponible
                                                    height="auto" // Ajusta la altura proporcionalmente para mantener la relación de aspecto
                                                    className="d-block mx-auto" // Centra la imagen horizontalmente
                                                    style={{
                                                        objectFit: 'contain', // Asegura que la imagen no se recorte y se ajuste sin deformarse
                                                        maxHeight: '80vh', // Limita la altura máxima de la imagen al 80% de la altura de la ventana
                                                        maxWidth: '100%' // Limita el ancho máximo al 100% del contenedor
                                                    }}
                                                />
                                                <Carousel.Caption>
                                                    <h3>{picture.title}</h3>
                                                    <p>{picture.description}</p>
                                                </Carousel.Caption>
                                            </Carousel.Item>
                                        )
                                    })
                                }
                            </Carousel>
                        ) : (
                            <div>
                                <Spinner />
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center align-items-center p-0">
                        {isLoaded && (
                            <Button size={'sm'} onClick={handleClose} variant='info'><i className="bi bi-arrow-left"></i> <FormattedMessage id="textBack" defaultMessage="Back" /></Button>
                        )}
                    </Modal.Footer>
                </Modal>
            </>
        )
    )
}

export default PhotosModal