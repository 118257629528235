import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Button, InputGroup, FormControl } from 'react-bootstrap'

const SearchBar = ({ searchValue, setSearchValue }) => {
    const intl = useIntl() // Accede a formatMessage

    const handleSetSearch = (e) => {
        e.preventDefault()
        setSearchValue(e.target.value)
    }

    const handleReset = (e) => {
        e.preventDefault()
        setSearchValue('')
    }

    return (
        <InputGroup className="mb-3 w-100 p-1">
            <InputGroup.Text>
                <i className="bi bi-search"></i>
            </InputGroup.Text>
            <FormControl
                className="form-control-sm"
                name="searchValue"
                type="text"
                placeholder={`${intl.formatMessage({
                    id: 'textSearch',
                    defaultMessage: 'Search',
                })}...`}
                value={searchValue}
                onChange={handleSetSearch}
                aria-autocomplete="false"
                autoComplete="false"
            />

            <Button
                variant="outline-secondary"
                size="sm"
                onClick={handleReset}
                disabled={!searchValue || searchValue === ''} // Deshabilita el botón si no hay texto
                style={{ display: 'flex', alignItems: 'center' }}
            >
                <i className="bi bi-x-circle"></i>&nbsp;
                <FormattedMessage id="textClear" defaultMessage="Clear" />
            </Button>
        </InputGroup>
    )
}

export default SearchBar