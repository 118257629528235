import React from "react"
import { useIntl, IntlProvider, FormattedMessage } from 'react-intl'
import { Page, Text, View, Document, StyleSheet, Image, Link } from '@react-pdf/renderer'

import moment from 'moment'

import translations_en from '../../lang/en.json'  // English
import translations_es from '../../lang/es.json'  // Español
import translations_fr from '../../lang/fr.json'  // Francais
const translations = {
    en: translations_en,
    es: translations_es,
    fr: translations_fr,
}

const locale = navigator.language.split('-')[0]
const defaultLocale = 'en'
const selectedTranslations = translations[locale] || translations[defaultLocale]

const TaskReportPDF = ({ task, images, group, user, intl }) => {
    //const intl = useIntl()
    const baseUrl = window.location.origin

    const authUser = JSON.parse(localStorage.getItem("user"))

    // Create styles
    const styles = StyleSheet.create({
        page: {
            backgroundColor: '#FFFFFF',
            color: '#000000',
            fontSize: 10
        },
        section: {
            margin: 2,
            padding: 2,
            marginBottom: 20,
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        sectionCabImage: {
            alignSelf: 'flex-start',
            height: 25
        },
        sectionCabText: {
            alignSelf: 'center'
        },
        sectionPageNum: {
            alignSelf: 'center'
        },
        table: {
            display: "flex",
            textAlign: "left",
            width: "100%",
            marginBottom: 2,
            flexDirection: 'row', //     flexDirection: "column",
        },
        row: {
            display: "flex",
            flexDirection: "row",
            width: '100%',
            borderBottomWidth: 1,
            borderBottomColor: '#BBB',
            paddingBottom: 5,
        },
        rowPlus: {
            display: "flex",
            flexDirection: "row",
            width: '100%',
            borderBottomWidth: 1,
            borderBottomColor: '#BBB',
            fontWeight: "bold",
            fontSize: 12,
            paddingBottom: 5,
        },
        header: {
            backgroundColor: '#ccc',
            fontWeight: 'bold',
            width: '20%',
            paddingRight: 8,
            textAlign: 'right',
            textOverflow: 'ellipsis',
            maxLines: 10,
        },
        cell: {
            flex: 1,
            textAlign: 'left',
            paddingLeft: 8,
            width: '80%',
            textOverflow: 'ellipsis',
            maxLines: 10,
        },
        hr: {
            padding: 20,
        },
        picture: {
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            width: "100%",
            maxWidth: "100%",
            maxHeight: "100% !Important",
            marginBottom: 2,
            flexDirection: 'row', //     flexDirection: "column",
            borderBottomWidth: 1,
            borderBottomColor: '#BBB',
            //backgroundColor: '#F00'
        },
        image: {
            //marginVertical: 15,
            //marginHorizontal: 100,
        }
    })

    const pdfLogo = (authUser?.frontConfig?.pdf?.urlLogo ?? '') !== ''
        ? authUser.frontConfig.pdf.urlLogo
        : '/logo_civilio_trans.png'

    const MyCab = () => (
        <View style={styles.section}>
            <Image src={pdfLogo} style={styles.sectionCabImage} />
            <Text style={styles.sectionCabText}>{document.title}</Text>
            <Text style={styles.sectionPageNum} render={({ pageNumber, totalPages }) => (
                `${intl.formatMessage({ id: 'textPage', defaultMessage: 'Page' })}: ${pageNumber}/${totalPages}`
            )} fixed />
        </View>
    )

    const MyRow = ({ id, defaultMessage, value }) => (
        <View style={styles.table}>
            <View style={styles.row}>
                <View style={styles.header}>
                    <Text>{intl.formatMessage({ id: id, defaultMessage: defaultMessage })}:</Text>
                </View>
                <View style={styles.cell}>
                    <Text>{value}</Text>
                </View>
            </View>
        </View>
    )

    const MyRowPictureLink = ({ id, defaultMessage, value }) => (
        <View style={styles.table}>
            <View style={styles.row}>
                <View style={styles.header}>
                    <Text>{intl.formatMessage({ id: id, defaultMessage: defaultMessage })}:</Text>
                </View>
                <View style={styles.cell}>
                    <Link src={value}>
                        <Text>{value}</Text>
                    </Link>
                </View>
            </View>
        </View>
    )

    // Create Document Component
    const MyDocument = ({ task, images, group }) => (
        <Document
            title={task.feature.ref + ': ' + task.feature.name}
            author={"CIVILIO Information Systems"}
            subject={group.form.title}
        >
            <Page size="A4" style={styles.page}>
                <MyCab />
                <MyRow id='reportInspectionId' defaultMessage='Inspection Id' value={task._id} />
                <MyRow id='reportGroup' defaultMessage='Group' value={group.name} />
                <MyRow id='reportMissionName' defaultMessage='Mission' value={task.mission.name} />
                <MyRow id='reportMissionLayer' defaultMessage='Mission layer' value={task.feature.layer} />
                <MyRow id='reportMissionDescription' defaultMessage='Mission Mission description' value={task.mission.description} />
                <MyRow id='reportInspectorName' defaultMessage='Inspector' value={user.name} />
                <MyRow id='reportInspectorEmail' defaultMessage='E-mail' value={user.email} />
                <MyRow id='reportInspectorPhone' defaultMessage='Phone Number' value={user.phone} />

                <View style={styles.hr}>
                </View>

                <MyRow id='featureReference' defaultMessage='Reference' value={task.feature.ref} plus={true} />
                <MyRow id='featureName' defaultMessage='Name' value={task.feature.name} plus={true} />
                <MyRow id='tasksCreatedAt' defaultMessage='Created at' value={moment(task.createdAt).format('MMMM Do YYYY, h:mm:ss a')} />
                <MyRow id='tasksStatus' defaultMessage='Status' value={task.status + ' - ' + moment(task.statusChangedAt).format('MMMM Do YYYY, h:mm:ss a')} />
                <MyRow id='tasksResult' defaultMessage='Result' value={task.result + ' - ' + moment(task.createdAt).format('MMMM Do YYYY, h:mm:ss a')} />
                <MyRow id='reportResultDescription' defaultMessage='Inspection description' value={task.resultDescription} />
                <MyRow id='reportTotalPictures' defaultMessage='Total pictures' value={images.length} />
            </Page>

            <Page size="A4" style={styles.page}>
                <MyCab />
                <MyRow id='reportFormId' defaultMessage='Form id' value={group.form._id} />
                <MyRow id='reportFormTitle' defaultMessage='Form title' value={group.form.title} />
                <MyRow id='reportFormDescription' defaultMessage='Form description' value={group.form.description} />
                <MyRow id='reportFormUpdated' defaultMessage='Form updated' value={moment(task.changedAt).format('MMMM Do YYYY, h:mm:ss a')} />

                {task.formData ? renderSchemaData(group.form, JSON.parse(task.formData)) :
                    <MyRow id='reportFormData' defaultMessage='Form data' value={intl.formatMessage({ id: 'reportNoData', defaultMessage: 'Data not received yet!' })} />
                }
            </Page>

            {images && images.map((image, index) => {
                return (
                    <Page key={'images-pdf-id-' + image._id} size="A4" style={styles.page}>
                        <MyCab />
                        <MyRow id='reportPictureNumber' defaultMessage='Picture number' value={(index + 1) + '/' + images.length} />
                        <MyRow id='featureReference' defaultMessage='Reference' value={task.feature.ref} />
                        <MyRow id='featureName' defaultMessage='Name' value={task.feature.name} />
                        <MyRow id='reportPictureTitle' defaultMessage='Picture title' value={image.title} />
                        <MyRow id='reportPictureDescription' defaultMessage='Picture description' value={image.description} />
                        <MyRow id='reportPictureDate' defaultMessage='Picture date' value={moment(image.changedAt).format('MMMM Do YYYY, h:mm:ss a')} />
                        <MyRowPictureLink id='reportPictureUrl' defaultMessage='Picture url' value={baseUrl + '/api/picture/' + image.tokenUrl} />
                        {true === true && // La versión de react-pdf/renderer tiene un problema de renderización de imágenes
                            <View style={styles.picture}>
                                <Image key={'image-' + image._id} src={baseUrl + '/api/picture/' + image.tokenUrl} style={{ maxWidth: "98%", maxHeight: "98%" }} />
                            </View>
                        }
                    </Page>
                )
            })}

        </Document>
    )

    const renderSchemaData = (schema, data, parentKey = '') => {
        const properties = schema.properties || {}
        const allOf = schema.allOf || []

        const mergedProperties = allOf.reduce((acc, item) => {
            if (item.if && item.then) {
                const conditionMet =
                    item.if.properties &&
                    Object.entries(item.if.properties).every(([conditionKey, conditionValue]) => data[conditionKey] === conditionValue.const)

                acc = conditionMet ? { ...acc, ...item.then.properties } : acc
            } else if (item.properties) {
                acc = { ...acc, ...item.properties }
            }

            return acc
        }, { ...properties })

        return (
            <View>
                {Object.entries(mergedProperties).map(([key, fieldSchema]) => {
                    const value = data[key]
                    const fullKey = parentKey ? `${parentKey}.${key}` : key

                    if (typeof value === 'object' && fieldSchema) {
                        return (
                            <View key={`schemaData-key-${fullKey}`} style={styles.table}>
                                <View style={styles.row}>
                                    <View style={styles.header}>
                                        <Text>{fieldSchema.title || key}</Text>
                                    </View>
                                    <View style={styles.cell}>
                                        {renderSchemaData(fieldSchema, value, fullKey)}
                                    </View>
                                </View>
                            </View>
                        )
                    } else if (value !== undefined) {
                        let valueStr = checkFeaturePropertiesValue(task.feature, value)
                        if (valueStr === 'false') {
                            valueStr = <FormattedMessage id="textNo" defaultMessage="No" />
                        } if (valueStr === 'true') {
                            valueStr = <FormattedMessage id="textYes" defaultMessage="Yes" />
                        }
                        return (
                            <View key={`schemaData-key-${fullKey}`} style={styles.table}>
                                <View style={styles.row}>
                                    <View style={styles.header}>
                                        <Text>{fieldSchema ? fieldSchema.title || key : key}</Text>
                                    </View>
                                    <View style={styles.cell}>
                                        <Text>{valueStr}</Text>
                                    </View>
                                </View>
                            </View>
                        )
                    }

                    return null
                })}
            </View>
        )
    }

    // En valores por defecto "default" si se usa $.properties.nombrePropiedad se cambia por el feature.properties.nombrePropiedad
    // En teoría debe hacerlo la APP cuando procesa el formulario para la toma de datos.
    const checkFeaturePropertiesValue = (feature, value) => {
        if (typeof value !== 'string' || !value) {
            return String(value) // Devolver value tal como es si no es una cadena o no está definido
        }

        return value.replace(/\$\.properties\.\w+/g, match => {
            const propertyName = match.substring(13) // Eliminar "$.properties." del principio

            // Verificar si task.feature y task.feature.properties están definidos
            if (feature && feature.properties && feature.properties[propertyName] !== undefined) {
                // Devolver el valor correspondiente si está definido
                return String(feature.properties[propertyName])
            } else {
                // Devolver la cadena original si no se encuentra
                return String(match)
            }
        })
    }

    return (
        <IntlProvider locale={locale} messages={selectedTranslations} defaultLocale="en">
            <MyDocument task={task} images={images} group={group} user={user} />
        </IntlProvider>
    )
}

export default TaskReportPDF