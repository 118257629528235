import moment from 'moment'

/**
 * Return the most recent date from an array of date strings
 * @param {string[]} dates - Array of date strings
 * @returns {moment.Moment} - Most recent date as a Moment.js object
 */
export const mostRecentDate = (dates = []) => {
    if (!Array.isArray(dates) || dates.length === 0) {
        throw new Error("Invalid or empty dates array")
    }

    const dateObjects = dates
        .map(date => moment(date))
        .filter(date => date.isValid()) // Filtrar fechas válidas

    if (dateObjects.length === 0) {
        throw new Error("No valid dates provided in array")
    }

    return moment.max(dateObjects)
}