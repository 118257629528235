import React from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

const exampleData = {
    labels: ['day1', 'day2'],
    datasets: [
        {
            label: 'Users',
            data: [1, 2],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            yAxisID: 'y',
        },
        {
            label: 'Tasks',
            data: [1, 2],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            yAxisID: 'y1',
        },
    ],
}

const LineChart = ({ title = false, data = exampleData, displayLegend = true }) => {
    // Obtener el valor máximo de inProgressAndPendingCount
    const maxPendingTasksCount = Math.max(...data.datasets.map(item => item.pendingTasksCount))
    // Obtener el valor máximo de tasksCount
    const maxTasksCount = Math.max(...data.datasets.map(item => item.tasksCount))
    // Obtener el valor máximo de usersCount
    const maxUsersCount = Math.max(...data.datasets.map(item => item.usersCount))
    const maxValue = Math.max(maxPendingTasksCount, maxTasksCount, maxUsersCount)

    const options = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: true,
        },
        stacked: false,
        plugins: {
            legend: {
                display: displayLegend,
                position: 'bottom',
            },
            title: {
                display: true,
                text: title,
            },
            tooltip: {
                mode: 'index', // Agrupar tooltips por etiquetas
                intersect: true,
                position: 'average', // average - nearest
            },
        },
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                min: 0,  // Empieza desde 0
                max: maxValue, // Asegurar que ambos ejes tengan el mismo valor máximo
                ticks: {
                    stepSize: 1, // Espaciado de las divisiones
                    callback: function (value) {
                        return value.toFixed(0); // Asegurar que los valores no tengan decimales
                    },
                },
            }
        },
    }


    return <Line options={options} data={data} />
}

export default LineChart