import { useState } from "react"
import Accordion from 'react-bootstrap/Accordion'
import ListGroup from 'react-bootstrap/ListGroup'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'
import Alert from 'react-bootstrap/Alert'
import { FormattedMessage } from 'react-intl'

import moment from 'moment'

import "./accordion.css"

import { taskStatusProperties, taskResultProperties } from 'helpers/Tasks'

import InfoModal from "./InfoModal"
import MapModal from "./MapModal"
import InspectionModal from "./InspectionModal"
import PhotosModal from "./PhotosModal"
import ReportModal from './ReportModal'

const TasksList = ({ tasksList, group, mapCenter, mapZoom, getTasks }) => {

    const [taskSelected, setTaskSelected] = useState([])

    const [activeDateAccordionKey, setActiveDateAccordionKey] = useState(null)
    const [activeTaskAccordionKey, setActiveTaskAccordionKey] = useState(null)

    const [infoModalShow, setInfoModalShow] = useState(false)
    const [mapModalShow, setMapModalShow] = useState(false)
    const [inspectionModalShow, setInspectionModalShow] = useState(false)
    const [photosModalShow, setPhotosModalShow] = useState(false)
    const [reportModalShow, setReportModalShow] = useState(false)

    const handleInfoBtn = (task) => {
        setTaskSelected(task)
        setInfoModalShow(true)
    }

    const handleMapBtn = (task) => {
        setTaskSelected(task)
        setMapModalShow(true)
    }

    const handleInspectionBtn = (task) => {
        setTaskSelected(task)
        setInspectionModalShow(true)
    }

    const handlePhotosBtn = (task) => {
        setTaskSelected(task)
        setPhotosModalShow(true)
    }

    const handleTaskReportBtn = (task) => {
        setTaskSelected(task)
        setReportModalShow(true)
    }

    // Ordena y agrupa las tareas por fecha
    const groupedTasks = tasksList
        ? tasksList.reduce((groups, task) => {
            // Encuentra la fecha más reciente entre statusChangedAt y resultChangedAt
            const date = new Date(Math.max(
                new Date(task.statusChangedAt).getTime(),
                new Date(task.resultChangedAt).getTime()
            ))

            // Formatea la fecha para el grupo (puedes cambiar el formato)
            const dateKey = moment(date).format('DD-MM-YYYY') // Agrupa por día

            // Agrega la tarea al grupo correspondiente
            if (!groups[dateKey]) {
                groups[dateKey] = []
            }
            groups[dateKey].push(task)

            return groups
        }, {})
        : {}

    // Ordena las fechas del grupo
    const sortedDates = Object.keys(groupedTasks).sort((a, b) => {
        // Usa Moment.js para convertir las fechas y compararlas
        return moment(b, 'DD-MM-YYYY').toDate() - moment(a, 'DD-MM-YYYY').toDate()
    })

    const TasksAccordion = (groupDate) => {
        return (
            <Accordion flush
                activeKey={activeTaskAccordionKey}
                onSelect={(key) => setActiveTaskAccordionKey(key)}
            >
                {groupDate.tasks.map((task, index) => {
                    task.name = task.feature.name
                    task.statusProperties = taskStatusProperties(task.status)
                    task.resultProperties = taskResultProperties(task.result, task.status)
                    const lastUpdateDate = moment.max(
                        moment(taskSelected.statusChangedAt),
                        moment(taskSelected.resultChangedAt)
                    ).toDate()
                    return (
                        <Accordion.Item key={'accordion-task-' + task._id} eventKey={index}>
                            <Accordion.Header bsPrefix={"my-accordion-header-" + task.resultProperties.bgcolor}>
                                <i className={task.resultProperties.icon}></i>&nbsp;{task.name}
                            </Accordion.Header>
                            <Accordion.Body className="p-0">
                                <ListGroup variant="flush">
                                    <ListGroup.Item variant="light"><i className="bi bi-cloud-plus"></i> {moment(task.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</ListGroup.Item>
                                    <ListGroup.Item variant="light"><i className="bi bi-cloud-upload"></i> {moment(lastUpdateDate).format('MMMM Do YYYY, h:mm:ss a')}</ListGroup.Item>

                                    <ListGroup.Item variant="light"><i className="bi bi-shield"></i> {group.event.name} / {group.name}</ListGroup.Item>

                                    {task.feature.properties && task.feature.properties['_message_'] &&
                                        <ListGroup.Item variant="light"><i className="bi bi-exclamation-triangle"></i> {task.feature.properties['_message_']}</ListGroup.Item>
                                    }

                                    <ListGroup.Item variant="light" className="d-flex w-100 align-items-center justify-content-between">
                                        <Badge bg={task.statusProperties.bgcolor}><i className={task.statusProperties.icon}></i> {task.statusProperties.text}</Badge>
                                        <small>{moment(task.statusChangedAt).fromNow()}</small>
                                    </ListGroup.Item>

                                    {task.result && task.result !== 'NONE' && (  // QUITO EL BOTON PARA QUE NO PUEDAN DESCARGAR PDF
                                        <ListGroup.Item variant="light" className="d-flex w-100 align-items-center justify-content-between">
                                            <Badge bg={task.resultProperties.bgcolor}><i className={task.resultProperties.icon}></i> {task.resultProperties.text}</Badge>
                                            <Button type="button" className={"btn btn-sm btn-" + task.resultProperties.bgcolor} onClick={() => handleTaskReportBtn(task)}><i className="bi bi-filetype-pdf"></i> <FormattedMessage id="textReport" defaultMessage="Report" /></Button>
                                            <small>{moment(task.resultChangedAt).fromNow()}</small>
                                        </ListGroup.Item>
                                    )}

                                    {task.resultDescription && task.resultDescription.length > 0 &&
                                        <ListGroup.Item variant="light"><i className="bi bi-card-text"></i> {task.resultDescription}</ListGroup.Item>
                                    }

                                    <ListGroup.Item variant="secondary">
                                        <div className="d-flex w-100 align-items-center justify-content-between p-0">
                                            <Button variant="primary" size="sm" onClick={() => handleInfoBtn(task)}><i className="bi bi-info-square-fill"></i></Button>{' '}
                                            <Button variant="primary" size="sm" onClick={() => handleMapBtn(task)}><i className="bi bi-map-fill"></i> <FormattedMessage id="navMap" defaultMessage="Map" /></Button>{' '}
                                            <Button variant="primary" size="sm" onClick={() => handleInspectionBtn(task)}><i className="bi bi-clipboard2-check-fill"></i> <FormattedMessage id="inspectionText" defaultMessage="Inspection" /></Button>{' '}
                                            <Button variant="primary" size="sm" onClick={() => handlePhotosBtn(task)}><i className="bi bi-camera-fill"></i> <FormattedMessage id="navPictures" defaultMessage="Pictures" /> <Badge bg="secondary" pill>{task.pictures.length}</Badge></Button>{' '}
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Accordion.Body>
                        </Accordion.Item>
                    )
                })}
            </Accordion>
        )
    }

    return (
        <div style={{ marginBottom: 40 }}>
            {tasksList && tasksList.length > 0 ? (
                <Accordion flush
                    activeKey={activeDateAccordionKey}
                    onSelect={(key) => setActiveDateAccordionKey(key)}
                >
                    {sortedDates.length > 0 ? (
                        sortedDates.map((date, index) => (
                            <Accordion.Item key={'accordion-date-' + index} eventKey={index}>
                                <Accordion.Header>
                                    <h6>
                                        <i className="bi bi-calendar4-event"></i>&nbsp; {date}&nbsp;
                                        <Badge bg="primary">
                                            {groupedTasks[date].length}&nbsp;
                                            <FormattedMessage id="navTasks" defaultMessage="Tasks" />
                                        </Badge>
                                    </h6>
                                </Accordion.Header>
                                <Accordion.Body className="p-0">
                                    {groupedTasks[date] && groupedTasks[date].length > 0 ? (
                                        <TasksAccordion tasks={groupedTasks[date]} />
                                    ) : (
                                        <p>No tasks available for this date.</p>
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        ))
                    ) : (
                        <p>No dates available.</p>
                    )}
                </Accordion>
            ) : (
                <div className="container p-1">
                    <Alert variant={'warning'}>
                        No task to show!
                    </Alert>
                </div>
            )}

            {infoModalShow && taskSelected &&
                <InfoModal task={taskSelected} group={group} callback={setInfoModalShow} />
            }
            {mapModalShow && taskSelected &&
                <MapModal task={taskSelected} group={group} callback={setMapModalShow} tasks={tasksList} mapCenter={mapCenter} mapZoom={mapZoom} />
            }
            {inspectionModalShow && taskSelected &&
                <InspectionModal task={taskSelected} group={group} callback={setInspectionModalShow} />
            }
            {photosModalShow && taskSelected &&
                <PhotosModal task={taskSelected} group={group} callback={setPhotosModalShow} />
            }
            {reportModalShow && taskSelected &&
                <ReportModal task={taskSelected} group={group} callback={setReportModalShow} />
            }
        </div>
    )
}

export default TasksList