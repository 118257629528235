import React from "react"
import { FormattedMessage } from 'react-intl'
import { Link } from "react-router-dom"
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'

const NavbarSupervisor = ({ state }) => {

    const { currentUser } = state

    return (
        <Navbar collapseOnSelect bg="dark" variant="light" expand="sm" sticky="top" className="mob-navbar-bg">
            <Container fluid>
                <Navbar.Brand bsPrefix="mob-bg-brand">
                    <Link to={"/"}>
                        <img
                            src="/logo_civilio_trans.png"
                            height="25"
                            className="d-inline-block align-top"
                            alt="CIVILIO Supervisor role"
                        />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="mobile-supervisor-navbar-nav" />
                <Navbar.Collapse id="mobile-supervisor-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Item>
                            <Link to={"/home"} className="nav-link"><i className="bi bi-speedometer"></i> <FormattedMessage id="navTasks" defaultMessage="Tasks" /></Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to={"/profile"} className="nav-link"><i className="bi bi-person-square"></i> {currentUser.name}</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to={"/logout"} className="nav-link"><i className="bi bi-door-closed-fill"></i> <FormattedMessage id="navLogout" defaultMessage="Logout" /></Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavbarSupervisor