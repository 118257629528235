import { useEffect, useState } from "react"
import { useIntl } from 'react-intl'
import { useMap, LayerGroup, FeatureGroup, GeoJSON, Popup, Tooltip } from "react-leaflet"
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'

import styles from "./inspectionsButton.module.css"
import L from "leaflet"

import _ from 'lodash'

import moment from 'moment'

import TasksService from 'services/TasksService'

import InspectionModal from '../../modals/InspectionModal'
import { taskStatusProperties, taskResultProperties } from 'helpers/Tasks'
import { markerIcon } from "helpers/Markers"

const InspectionsButton = ({ groupId, missionId, userId, searchValue, mapSliderValue, refresh, onError }) => {
    const intl = useIntl()

    const map = useMap()

    const [tasks, setTasks] = useState(false)

    const [modalTrigger, setModalTrigger] = useState(false)
    const [modalType, setModalType] = useState(false)
    const [taskSelected, setTaskSelected] = useState(false)

    const [showTasks, setShowTasks] = useState(false)

    const getTasks = async () => {
        const mapBounds = map.getBounds()
        const options = {
            groupId: groupId,
            missionId: missionId,
            userId: userId,
            search: searchValue,
            dateFilter: 'result',
            lonbl: mapBounds._southWest.lng,
            latbl: mapBounds._southWest.lat,
            lonur: mapBounds._northEast.lng,
            latur: mapBounds._northEast.lat,
            timestamp: mapSliderValue
        }

        await TasksService.getGroupTasks(options).then(
            response => {
                setTasks(response.data.tasks)
                onError(false)
            },
            error => {
                onError(error)
            })

    }

    const handleFeatureClick = (geoJ) => {
        if (geoJ.layer.feature.geometry.type !== 'Point') {
            geoJ.layer.bringToBack()
        }
    }

    const handleFeatureMouseOver = (geoJ, featureColor) => {
        if (geoJ.layer.feature.geometry.type !== "Point") {
            geoJ.layer.setStyle({ color: "grey", fillColor: featureColor, fillOpacity: 1 })
        } else {
            if (geoJ.layer.getElement() !== null) {
                geoJ.layer.getElement().querySelector('path').style.fill = featureColor
            }
        }
    }

    const handleFeatureMouseOut = (geoJ, featureColor) => {
        if (geoJ.layer.feature.geometry.type !== "Point") {
            geoJ.layer.setStyle({ color: 'black', fillColor: featureColor, fillOpacity: 1 })
        } else {
            if (geoJ.layer.getElement() !== null) {
                geoJ.layer.getElement().querySelector('path').style.fill = featureColor
            }
        }

    }

    const handleEditTaskBtn = (task) => {
        setModalType('edit')
        setTaskSelected(task)
        setModalTrigger(true)
    }

    const handleDeleteTaskBtn = (task) => {
        setModalType('delete')
        setTaskSelected(task)
        setModalTrigger(true)
    }

    const handleModalCallBack = (update) => {
        setModalTrigger(false)
        if (update) {
            getTasks()
        }
    }

    const pointToLayer = (geoJsonPoint, latlng) => {
        return L.marker(latlng, { icon: markerIcon(geoJsonPoint.resultProps.fillcolor) })
    }

    useEffect(() => {
        if (!map) return
        // create custom button
        const customControl = L.Control.extend({
            // button position
            options: {
                position: "topright",
                className: `${styles.inspectionsButton} leaflet-bar`,
                html: '<i class="bi bi-clipboard2-check-fill"></i>',
                style: "width: 34px; height: 34px; left: 0; margin-top: 0; display: flex; cursor: pointer; justify-content: center; font-size: 1rem;",
            },

            // method
            onAdd: function (map) {
                this._map = map
                const button = L.DomUtil.create("div")
                L.DomEvent.disableClickPropagation(button)

                button.title = intl.formatMessage({ id: 'mapInspections', defaultMessage: 'Display inspections results' })
                button.innerHTML = this.options.html
                button.className = this.options.className
                button.setAttribute("style", this.options.style)

                L.DomEvent.on(button, "click", this._clicked, this)

                return button
            },
            _clicked: function (e) {
                L.DomEvent.stopPropagation(e)

                const inspectionsBtnActive = document.querySelector(`.${styles.inspectionsButton}`)
                const inspectionsBtn = inspectionsBtnActive.classList.contains(styles.inspectionsActive)
                // add/remove class from inspections button
                inspectionsBtnActive.classList[inspectionsBtn ? "remove" : "add"](styles.inspectionsActive)

                // Click funtion
                ///////////////////////////
                setShowTasks(!inspectionsBtn)

                return
            }
        });

        // adding new button to map controll
        map.addControl(new customControl())
    }, [map])


    useEffect(() => {
        if (!map) return
        if (!showTasks) return

        getTasks()

        const getTasksThrottled = _.throttle(() => {
            getTasks()
        }, 1000) // 1000ms 

        const onMoveEnd = () => {
            getTasksThrottled()
        }

        const interval = setInterval(() => {
            getTasks()
        }, 60000) // 60000ms = 1 minute

        map.on('moveend', onMoveEnd)
        return () => {
            map.off('moveend', onMoveEnd)
            getTasksThrottled.cancel()
            clearInterval(interval)
        }

    }, [showTasks, missionId, userId, searchValue, mapSliderValue, refresh])

    return (
        map && showTasks && tasks && (
            <>
                <LayerGroup
                    attribution="Civilio inspections results"
                    color="red"
                    key={"lg-insp-" + groupId}
                    eventHandlers={{
                        /*
                                            add: (layerG) => {
                                                //getData(layerG)
                                                //map.fitBounds(layerGroupRef.current._map.getBounds());
                                                //setDataDownloaded(true)
                                            },
                                            remove: (layerG) => {
                                                //deleteData(layerG)
                                                //setDataDownloaded(false)
                                            }
                                            */
                    }}
                >
                    {showTasks && tasks.map((task, index) => {
                        task.feature.type = "Feature"
                        task.feature.statusProps = taskStatusProperties(task.status)
                        const resultDate = moment(task.resultChangedAt)
                        const timeDate = moment(mapSliderValue ? mapSliderValue : Date.now())
                        if (resultDate.isSameOrBefore(timeDate)) {
                            task.feature.resultProps = taskResultProperties(task.result, task.status)
                        } else {
                            task.feature.resultProps = taskResultProperties('NONE', task.status)
                        }
                        const style = {
                            weight: 2,
                            opacity: 1,
                            color: "black",
                            //dashArray:3,
                            fillOpacity: 1,
                            fillColor: task.feature.resultProps.fillcolor
                        }
                        return (
                            <GeoJSON
                                key={"lg-insp-" + groupId + '-' + task.mission._id + "-fg-fea-" + task._id}
                                data={task.feature}
                                style={style}
                                eventHandlers={{
                                    click: (geoJ) => {
                                        handleFeatureClick(geoJ, task.feature.resultProps.fillcolor)
                                    },
                                    mouseover: (geoJ) => {
                                        handleFeatureMouseOver(geoJ, 'blue')
                                    },
                                    mouseout: (geoJ) => {
                                        handleFeatureMouseOut(geoJ, task.feature.resultProps.fillcolor)
                                    },
                                    popupopen: (geoJ) => {
                                        //handleFeaturePopupOpen(geoJ)
                                    },
                                    popupclose: (geoJ) => {
                                        handleFeatureMouseOut(geoJ, task.feature.resultProps.fillcolor)
                                    },
                                    add: (geoJ) => {
                                    },
                                    remove: (geoJ) => {
                                    }
                                }}
                                pointToLayer={task.feature.geometry.type === 'Point' ? pointToLayer : false}
                            >
                                <Tooltip direction="top" offset={[0, 0]} opacity={1} sticky={true} >
                                    {task.feature.name}
                                </Tooltip>
                                <Popup
                                    key={"lg-insp-" + groupId + '-' + task.mission._id + "-pop-" + task._id}
                                    maxWidth={500}
                                    minWidth={200}
                                >
                                    <p className="d-flex justify-content-end">
                                        <span className="me-auto">
                                            <i className="bi bi-clipboard2-fill"></i> Inspections result
                                        </span>
                                        {index + 1}/{tasks.length}
                                    </p>

                                    <div className="text-primary">
                                        <hr />
                                    </div>

                                    <p className="h6">{task.feature.name}</p>

                                    <p className="text-end"><small className="text-end">{moment(task.createdAt).fromNow()}</small></p>

                                    <p><i className="bi bi-lightning-fill"></i> {task.mission.name}</p>
                                    <p><i className="bi bi-layers-half"></i> {task.feature.layer}</p>
                                    <p><i className="bi bi-people-fill"></i> {task.user.name}</p>
                                    <p><i className="bi bi-binoculars-fill"></i> {' '}
                                        <Badge bg={task.feature.statusProps.bgcolor} text={task.feature.statusProps.textcolor}>
                                            {task.feature.statusProps.text} {moment(task.statusChangedAt).fromNow()}
                                        </Badge>
                                    </p>
                                    <p><i className="bi bi-house-fill"></i> {' '}
                                        <Badge bg={task.feature.resultProps.bgcolor} text={task.feature.resultProps.textcolor}>
                                            {task.feature.resultProps.text} {moment(task.resultChangedAt).fromNow()}
                                        </Badge>
                                    </p>
                                    <p>
                                        <i className="bi bi-images"></i>&nbsp;
                                        <span>{task.pictures.length}</span>
                                    </p>

                                    <div className="text-secondary">
                                        <hr />
                                    </div>

                                    <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                                        <Button type="button" className="btn btn-sm btn-light btn-outline-primary" onClick={() => handleEditTaskBtn(task)}><i className="bi bi-pencil-square"></i> Edit</Button>
                                        <Button type="button" className="btn btn-sm btn-light btn-outline-danger" onClick={() => handleDeleteTaskBtn(task)}><i className="bi bi-trash"></i> Delete</Button>
                                    </div>

                                    <div className="text-primary">
                                        <hr />
                                    </div>
                                </Popup>
                            </GeoJSON>
                        )
                    })
                    }
                </LayerGroup>

                {modalTrigger && (
                    <InspectionModal show={modalTrigger} modalType={modalType} task={taskSelected} callBack={handleModalCallBack} />
                )}
            </>
        )
    )
}

export default InspectionsButton
