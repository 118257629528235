import React, { useEffect, useState, useRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import TableList from "../components/tables/TableList"

import ConfigService from 'services/ConfigService'
import EventsService from 'services/EventsService'

import SearchBar from 'components/bars/SearchBar'

import EventsForm from './EventsForm'

import { AlertError, AlertLoading } from 'helpers/AlertHelper'

const Events = ({ update, updateFunction }) => {
  const intl = useIntl()

  const fronConfig = ConfigService.getFrontConfig()

  const [keys, setKeys] = useState({})
  const [events, setEvents] = useState([])
  const [pagination, setPagination] = useState([])
  const [currPage, setCurrPage] = useState(1)
  const [search, setSearch] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(false)

  const goToPage = (pageNum) => {
    setCurrPage(pageNum)
  }

  const refreshData = () => {
    setIsLoaded(false)
    getData()

    // Update sidebar event&groups accordion Is a trigger passed throught app.js -> sidebar.js -> accordion.js
    updateFunction(true)
  }

  const getData = async () => {
    await EventsService.getEvents(currPage, search, fronConfig.app.itemsListLimit).then(
      response => {
        setKeys({
          name: <FormattedMessage id="eventsName" defaultMessage="Name" />,
          hazardType: <FormattedMessage id="eventsHazardType" defaultMessage="Hazard type" />,
          createdAt: <FormattedMessage id="eventsCreatedAt" defaultMessage="Created at" />,
          active: <FormattedMessage id="eventsActive" defaultMessage="Active" />
        })
        setEvents(response.data.events)
        setPagination(response.data.pagination)
        setIsLoaded(true)
      },
      error => {
        setError(error)
      }
    )
  }

  const prevSearch = useRef()
  useEffect(() => {
    if (!prevSearch.current || prevSearch.current !== search) {
      prevSearch.current = search
      setIsLoaded(false)
    }
    setCurrPage(1)
    getData()
  }, [search])

  const prevCurrentPage = useRef()
  useEffect(() => {
    if (!prevCurrentPage.current || prevCurrentPage.current !== currPage) {
      prevCurrentPage.current = currPage
      setIsLoaded(false)
    }
    getData()
  }, [currPage])

  if (error) {
    return (
      <AlertError error={error} />
    )
  } else if (!isLoaded && search === '') {
    return (
      <AlertLoading isLoaded={isLoaded} />
    )
  }

  return (
    <>
      <SearchBar searchValue={search} setSearchValue={setSearch} />

      <TableList
        caption={intl.formatMessage({ id: 'events', defaultMessage: 'Events' })}
        icon="bi-calendar3"
        data={events}
        keys={keys}
        pagination={true}
        paginationData={pagination}
        goToPageFunction={goToPage}
        refreshData={refreshData}
        titleAdd={intl.formatMessage({ id: 'eventsAdd', defaultMessage: 'Add event' })}
        titleEdit={intl.formatMessage({ id: 'eventsEdit', defaultMessage: 'Edit event' })}
        titleDelete={intl.formatMessage({ id: 'eventsDelete', defaultMessage: 'Delete event' })}
        ElementForm={EventsForm}
      />
    </>
  )

}

export default Events