import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Carousel from 'react-bootstrap/Carousel'


const PicturesModal = ({ show, task, callBack }) => {

    const [taskPictures, setTaskPictures] = useState(task.pictures)
    const [showModal, setShowModal] = useState(false)

    const handleClose = () => {
        callBack(false)
        setShowModal(false)
    }
    const handleShowModal = () => setShowModal(true)

    useEffect(() => {
        if (!show) return
        handleShowModal()
    }, [show])

    return (
        <Modal
            show={showModal}
            onHide={handleClose}
            backdrop={"static"}
            keyboard={false}
            fullscreen={false}
            aria-labelledby={"task-pictures-modal"}
            size="lg"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id={"task-pictures-modal-title"}><i className="bi bi-images"></i>&nbsp;
                    <FormattedMessage id="navPictures" defaultMessage="Pictures" />&nbsp;
                    {task.feature.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-secondary">
                <Carousel>
                    {
                        taskPictures.map((picture) => {
                            return (
                                <Carousel.Item key={'picture-show' + picture.id}>
                                    <img
                                        src={'/api/picture/' + picture.tokenUrl + '/?imgWidth=800'}
                                        alt={picture.title}
                                        width="100%" // Asegura que la imagen ocupe todo el ancho disponible
                                        height="auto" // Ajusta la altura proporcionalmente para mantener la relación de aspecto
                                        className="d-block mx-auto" // Centra la imagen horizontalmente
                                        style={{
                                            objectFit: 'contain', // Asegura que la imagen no se recorte y se ajuste sin deformarse
                                            maxHeight: '80vh', // Limita la altura máxima de la imagen al 80% de la altura de la ventana
                                            maxWidth: '100%' // Limita el ancho máximo al 100% del contenedor
                                        }}
                                    />
                                    <Carousel.Caption>
                                        <h3>{picture.title}</h3>
                                        <p>{picture.description}</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            )
                        })
                    }
                </Carousel>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    <i className="bi bi-x-square"></i> <FormattedMessage id="textClose" defaultMessage="Close" />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PicturesModal

