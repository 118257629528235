import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import ListGroup from 'react-bootstrap/ListGroup'

import moment from 'moment'

import ConfigService from 'services/ConfigService'
import MissionsService from 'services/MissionsService'

import Spinner from "components/spinner/Spinner"
import MissionModal from 'components/modals/MissionModal'
import Pagination from "components/pagination/TablePagination";

const MissionsList = ({ groupId, missionId, userId, searchValue, callBack }) => {

    const fronConfig = ConfigService.getFrontConfig()

    const [modalTrigger, setModalTrigger] = useState(false)
    const [modalType, setModalType] = useState(false)
    const [missionSelected, setMissionSelected] = useState(false)

    const [missions, setMissions] = useState(false)
    const [pagination, setPagination] = useState(false)

    const getMissions = async (pageNum = 1) => {
        const options = {
            groupId: groupId,
            missionId: missionId,
            userId: userId,
            search: searchValue,
            pageNum: pageNum,
            limit: fronConfig.app.itemsListLimit
        }

        await MissionsService.getGroupMissions(options)
            .then(res => {
                setMissions(res.data.missions)
                setPagination(res.data.pagination)
            },
                error => {
                    setMissions(false)
                })

    }

    const goToPage = (pageNum) => {
        getMissions(pageNum)
    }

    const handleEditBtn = (mission) => {
        setModalType('edit')
        setMissionSelected(mission)
        setModalTrigger(true)
    }

    const handleDeleteBtn = (mission) => {
        setModalType('delete')
        setMissionSelected(mission)
        setModalTrigger(true)
    }

    const handleModalCallBack = (update) => {
        setModalTrigger(false)
        if (update) {
            getMissions()
            callBack(true)
        }
    }

    useEffect(() => {
        getMissions()

        const interval = setInterval(() => {
            getMissions()
        }, 60000) // 60000ms = 1 minute

        return () => {
            clearInterval(interval)
        }
    }, [missionId, userId, searchValue])

    if (!missions) {
        return (
            <Spinner />
        )
    }
    return (
        <div>
            <Table hover>
                <thead>
                    <tr className="table-secondary">
                        <th scope="col">#</th>
                        <th><i className="bi bi-lightning-fill"></i> <FormattedMessage id="missionsText" defaultMessage="Mission" /></th>
                        <th><i className="bi bi-layers-half"></i> <FormattedMessage id="missionsTopLayer" defaultMessage="Top layer" /></th>
                        <th className='text-end'><i className="bi bi-people-fill"></i> <FormattedMessage id="missionsInspectors" defaultMessage="Inspectors" /></th>
                        <th className='text-end'><i className="bi bi-clipboard2-fill"></i> <FormattedMessage id="missionsTasks" defaultMessage="Tasks" /></th>
                        <th className='text-end'><i className="bi bi-calendar-event-fill"></i> <FormattedMessage id="missionsCreatedAt" defaultMessage="Created" /></th>
                        <th className='text-end'><i className="bi bi-calendar-event-fill"></i> <FormattedMessage id="missionsUpdatedAt" defaultMessage="Updated" /></th>
                        <th className='text-end'></th>
                    </tr>
                </thead>
                <tbody>
                    {missions && missions.map((mission, index) =>
                        <tr key={"tr-mission-" + mission._id}>
                            <th scope="row">{index + 1 + pagination.skipItems}</th>
                            <td>{mission.name}</td>
                            <td>{mission && mission.layer && mission.layer.name ? mission.layer.name : 'No layer'}</td>
                            <td className='text-end'>
                                {mission.users.length} &nbsp;
                                <OverlayTrigger
                                    trigger={["hover", "focus"]}
                                    key={`tr-mission-${mission._id}-trigger`}
                                    placement="right"
                                    overlay={
                                        <Popover id={`tr-mission-${mission._id}-popover`}>
                                            <Popover.Header as="h3"><FormattedMessage id="missionsInspectors" defaultMessage="Inspectors" /> {mission.name}</Popover.Header>
                                            <Popover.Body>
                                                <ListGroup variant="flush" className='p-0'>
                                                    {mission.usersInfo.map((user) => {
                                                        return (
                                                            <ListGroup.Item key={`tr-mission-${mission._id}-popover-${user._id}`} className='p-1'>
                                                                <i className="bi bi-person-fill"></i> <strong>{user.name}</strong><br />
                                                                <i className="bi bi-phone"></i> {user.phone}<br />
                                                                <i className="bi bi-envelope"></i> {user.email}
                                                            </ListGroup.Item>
                                                        )
                                                    })}
                                                </ListGroup>
                                            </Popover.Body>
                                        </Popover>
                                    }
                                >
                                    <i className="bi bi-people-fill"></i>
                                </OverlayTrigger>
                            </td>
                            <td className='text-end'>
                                {mission.features.length} <i className="bi bi-clipboard2-fill"></i>
                            </td>
                            <td className='text-end'>
                                {moment(mission.createdAt).fromNow()}
                            </td>
                            <td className='text-end'>
                                {moment(mission.changedAt).fromNow()}
                            </td>
                            <td>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <Button type="button" className="btn btn-sm btn-light btn-outline-primary" onClick={() => handleEditBtn(mission)}><i className="bi bi-pencil-square"></i> <FormattedMessage id="textEdit" defaultMessage="Edit" /></Button>
                                    <Button type="button" className="btn btn-sm btn-light btn-outline-danger" onClick={() => handleDeleteBtn(mission)}><i className="bi bi-trash"></i> <FormattedMessage id="textDelete" defaultMessage="Delete" /></Button>
                                </div>
                            </td>
                        </tr>
                    )
                    }
                </tbody>
            </Table>

            {pagination && pagination.totalPages > 1 && (
                <Pagination data={pagination} goToPageFunction={goToPage} />
            )}

            {modalTrigger && (
                <MissionModal show={modalTrigger} modalType={modalType} mission={missionSelected} callBack={handleModalCallBack} />
            )}
        </div>
    )
}

export default MissionsList